/* .CheckboxRow {
    height: 1.8rem;
    font-family: "RobotoCondensedBold";
    font-size: 1.3rem;
    color: var(--coalLight);
    letter-spacing: -0.02rem;
    user-select: none;
}

.CheckboxRowInverted {
    flex-direction: row-reverse;
}

.Checkbox {
    border: 0.18rem solid var(--coalVeryLight);
    border-radius: 0.3rem;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.75rem;
    transition: all 0.2s ease;
    cursor: pointer;
    margin-right: 0.75rem;
  }

.CheckboxInverted {
    margin-left: 0.75rem;

}
  
.Checkbox:hover {
    background: #F4EEFF;
    border-radius: 0.45rem;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.65rem;
    margin-left: -0.1rem;
}

.CheckboxInverted:hover {
    margin-left: 0.65rem;
    margin-row: -0.1rem;
}
  
.Check {
    width: 0rem;
    height: 0rem;
    background: var(--coalLight); 
    border-radius: 0.225rem;
    transition: all 0.3s linear;
}
  
.Checked {
    width: 1rem;
    height: 1rem;
  }
   */


.TagSelector {
    width: 100%;
    align-items: flex-start;
    font-family: "RobotoCondensedBold";
    font-size: 1.4rem;
    flex-direction: column;
}

.TagHeader {
    color: var(--coalLight);

}

.TagsRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0 0rem 0;
}

.Tag {
    border: 2px solid var(--leaf);
    margin-right: 0.5rem;
    margin-bottom: 0.7rem;
    border-radius: 0.9rem;
    color: var(--leaf);
    padding: 0.3rem 0.8rem 0.3rem 0.8rem;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;
}

.TagSelected {
    color: #fff;
    background: var(--leaf);
}
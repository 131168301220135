.container{
  flex-direction: column;
  padding-top: 2rem;
  width: 100%;
}

.logoContainer {
  height: 13rem;
  width: 13rem;
  border-radius: 50%;
  outline: 1px solid var(--grey);
  margin-bottom: 2rem;
  background: #fff;
  filter: drop-shadow(0px 0px 0.7rem rgba(0, 0, 0, 0.15));
}

.logo{
  max-height: 10rem;
}

.buttonRow {
  gap: 2rem;
  width: 100%;
}

.logoContainer {
    height: 34%;
    border-radius: 50%;
    position: absolute;
    top: 7vh;
    z-index: 3;
}

  
.bbbLogo {
    margin-top: 1vh;
    height: 100%;
}

.successHeader {
    margin-bottom: 0rem;
}

.successText {
    width: 50%;
    text-align: center;
}

.successContent {
    width: 90%;
    height: 100%;
    margin-top: 10vh;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.9s;
}

.successContentHidden {
    opacity: 0;
}

.checkboxRow {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 1.2rem;
}

.buttonRow {
    flex-direction: column;
    margin-top: 1.2rem;
    width: 50%;
}


.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.contentGrid {
  width: 100%;
  margin-left: 0px;
  position: relative;
}

.headerRow {
  width: 100%;
  margin-top: -0.7rem;
  height: 3.4rem;   
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.6rem;
  font-family: "TruenoRg";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
}

.headerFilter {
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

.headerFilter:hover {
  opacity: 0.6;
}

.headerFilterSelected {
  height: calc(100% - 2px);
  color: var(--coalLight);
  border-bottom: 2px solid var(--coalLight);
}

.orderTable {
  width: 100%;
  margin-top: 1rem;
  border-spacing: 0 1.2rem;
}

.orderHeaderRow{
  text-align: left;
}

th:first-child{
  padding-left: 1rem;
}

td:first-child{
  padding-left: 1.4rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -webkit-border-radius: 1rem 0 0 1rem;
}
td:last-child{
  -moz-border-radius: 0 1rem 1rem 0;
  -webkit-border-radius: 0 1rem 1rem 0;
}



.tableRow{
  
  width: 100%;
  height: 5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: left;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
}

.dataBold {
  font-weight: 700;
}

.exp {
  font-size: 0.8rem;
  position: relative;
  bottom: 0.5rem;
}


.statusDelivered {
  color: var(--leaf);
  font-weight: 700;
}

.statusUpcoming {
  color: var(--coal);
  font-weight: 700;
}

.statusCancelled {
  color: var(--error);
  font-weight: 700;
}

.statusIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 0.4rem;
}


.newZoneButtonContainer {
  width: 140px;
  position: absolute;
  top: -80px;
  right: 70px;
}

.geometrySettingsContainer {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.toggleSwitchContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "RobotoCondensedMed";
}

.resetButtonContainer {
  height: 100%;
  padding: 10px 0 !important; 
  width: 100px;
}

.contentWrapper {
  padding: 0rem 1rem;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.detailsRow { 
  margin-top: 1.8rem;
  width: 100%;
  gap: 4rem;
  justify-content: space-between;
  align-items: flex-start;
  
  margin-bottom: 1.4rem;
}

.halfRow {
  padding-right: calc(50% + 1.5rem);
}

.buttonRow {
  margin-top: 2rem;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}

.container {
  width: 100%;
  opacity: 1;
}

.zoneContainer {
  width: 100%;
  
  opacity: 1;
  padding: 0px 20px 20px 20px;
  position: relative;
}
.ZoneDetailsContainer {
  display: flex;
  flex-direction: column;
}

.ZoneDetailsGrid {
  display: flex;
  flex-direction: column;
}

.ZoneDetailsRow {
  display: flex;
  flex-direction: column;
}

.applicationsSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  min-height: 100%;
  margin-right: 1.4rem;
}

.zoneDetailsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0 0;
  height: 2.8rem;
  border-bottom: 1px solid var(--greyLight);
}

.iconsContainer {
  position: absolute;
  right: 3rem;
  top: 1rem;
}

.editIcon {
  font-family: "FontAwesomeSolid";
  font-size: 1.6rem;
  cursor: pointer;
  margin-right: 1.2rem;
}

.editIcon:hover {
  opacity: 0.5;
}

.applicationCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  /* background: var(--greyLight); */
  border: 1px solid var(--greyLight); 
  width: 100%;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  min-height: 10rem;
}

.deleteZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 300px;
}

.deleteInput {
  width: 100%;
}



.applicationHeaders {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "TruenoSBd";
  color: var(--coalVeryLight);
  border-bottom: 1px solid var(--greyLight);
}

.fieldInfoSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 350px;
  padding: 0 1rem;

}

.fieldInfoGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.applicationTags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
}

.placeholderApplicationTags {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
}

.tag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 1rem;
  background: var(--coal);
  color: white;
  font-family: "RobotoCondensedMed";
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.placeholder {
  width: 100%;
}

.noZoneSelected {
  font-size: 1.6rem;
  margin-top: 2rem;
  width: 100%;
}

.placeholderTag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 1rem;
  background: var(--grey);
  color: white;
  font-family: "RobotoCondensedMed";
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.summaryContainer {
  /* background: var(--greyLight); */
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0rem;

}

.summaryItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "RobotoCondensedMed";
  color: var(--greyDark);
  border-radius: 30px;
  font-size: 1.6rem;
  padding: 1rem 2rem;
}

.summaryItemDark {
  background-color: var(--greyVeryLight);
}

.summaryValue {
  font-family: "RobotoCondensedBold";
  color: var(--coalLight);
}

.graphContainer {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid var(--greyLight); */
}

.graphContent {
  height: 100px;
  width: 100%;
  background-color: var(--greyVeryLight);
  border-radius: 30px;
}


.doubleHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "RobotoCondensedMed";
  font-size: 1.6rem;
}

.headerContainer {
  padding: 0.6rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: fit-content; */
  font-family: "RobotoCondensedMed";
  font-size: 1.6rem;


}

.headerLineContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "RobotoCondensedMed";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
  margin-bottom: 1.5rem;


}

.headerValue {
  margin-left: 1rem;
  color: var(--leaf);

}

.gaugeRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.gaugeContainer {
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gaugeItem {
  display: flex;
  flex-direction: column;
}

.gaugeLabel {
display: flex;
flex-direction: column;
margin-top: 0.5rem;
}

.targetRate {
  margin-top: 0.3rem;
  font-family: "RobotoCondensedMed";
}

.targetValue {
  margin-left: 0.6rem;
  color: var(--leaf);
  font-size: 1.5rem;
  font-family: "RobotoCondensedBold";
}
.applicationsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.applicationRows {
  width: 100%;
}

.applicationRow {
  width: 100%;
}

.separatorLine {
  width: 100%;
  border-top: 1px solid var(--greyLight);
  margin-top: 1.2rem;
}

.fieldType {
  margin-top: 1rem;
  font-family: "RobotoCondensedBold";
}

.fieldTypeIcon {
  max-height: 2rem;
  margin-right: 0.6rem;
  margin-top: -0.2rem;
}


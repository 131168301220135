body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

:root { /*color variables*/
  font-size: 62.5%;
  --leafDark: #278817;
  --leaf: #34B61f;
  --leafLight: #67C857;
  --leafVeryLight: #99D88F;

  --grassDark: #9BC500;
  --grass: #B0E000;
  --grassLight: #C5E842;
  --grassVeryLight: #DBF18C;

  --coalDark: #0D0D0D;
  --coal: #323232;
  --coalLight: #575757;
  --coalVeryLight: #7a7a7a;

  --greyDark: #989898;
  --grey: #c1c1c1;
  --greyLight: #dedede;
  --greyVeryLight: #ececec;

  --errorDark: #B33D23;
  --error: #F06F53;
  --errorLight: #F27D63;
  --errorVeryLight: #F27D63;
}

/* 
src/index.css
src/fonts/Trueno/TruenoBlk.ttf
src/fonts/Trueno/TruenoBlkIt.ttf
src/fonts/Trueno/TruenoExBd.ttf
src/fonts/Trueno/TruenoRg.ttf
src/fonts/Trueno/TruenoSBd (1).ttf
src/fonts/Trueno/TruenoSBd.ttf */



/*


@font-face {
  font-family: 'TruenoBd';
  src: url('/fonts/Trueno/TruenoBd.woff') format('woff');
}

@font-face {
  font-family: 'TruenoSBd';
  src: url('/fonts/Trueno/TruenoSBd.woff') format('woff');
}

@font-face {
  font-family: 'TruenoBlk';
  src: url('/fonts/Trueno/TruenoBlk.woff') format('woff');
}

@font-face {
  font-family: 'TruenoRg';
  src: url('/fonts/Trueno/TruenoRg.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMed';
  src: url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url('/fonts/RobotoCondensed/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedMed';
  src: url('/fonts/RobotoCondensed/RobotoCondensed-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: url('/fonts/RobotoCondensed/RobotoCondensed-Bold.ttf') format('truetype');
}

/* - FONTAWESOME - */ /*

@font-face {
  font-family: 'FontAwesomeBrands';
  src: url('/fonts/FontAwesome/fa-brands-400.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesomeSolid';
  src: url('/fonts/FontAwesome/fa-solid-900.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/FontAwesome/fa-regular-400.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesomeLight';
  src: url('/fonts/FontAwesome/fa-light-300-pro-5.11.0.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome6';
  src: url('/fonts/FontAwesome/fontAwesome6FreeRegular400.otf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome6Solid';
  src: url('/fonts/FontAwesome/fontAwesome6FreeSolid900.otf') format('truetype');
}

*/


@font-face {
  font-family: 'TruenoBd';
  src: /*local('Trueno'), */url(./fonts/Trueno/TruenoBd.woff) format('woff');
}
/* 
@font-face {
  font-family: 'TruenoBd';
  src: /*local('Trueno'), url(./fonts/Trueno/TruenoBd.ttf) format('woff');
} 
*/

@font-face {
  font-family: 'TruenoSBd';
  src: /*local('Trueno'), */url(./fonts/Trueno/TruenoSBd.woff) format('woff');
}

@font-face {
  font-family: 'TruenoBlk';
  src: /*local('Trueno'), */url(./fonts/Trueno/TruenoBlk.woff) format('woff');
}

@font-face {
  font-family: 'TruenoRg';
  src: /*local('Trueno'), */url(./fonts/Trueno/TruenoRg.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: /*local('RobotoRegular'),*/ url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoMed';
  src: /*local('RobotoMedium'), */url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  src: /*local('RobotoCondensed'), */url(./fonts/RobotoCondensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedMed';
  src: /*local('RobotoCondensedMed'), */url(./fonts/RobotoCondensed/RobotoCondensed-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: /*local('RobotoCondensed'), */url(./fonts/RobotoCondensed/RobotoCondensed-Bold.ttf) format('truetype');
}


/*FONTAWESOME*/


@font-face {
  font-family: 'FontAwesomeSolid';
  src: /*local('FontAwesomeSolid'), */url(./fonts/FontAwesome/fontAwesome5ProSolid900.otf) format('opentype');
}

@font-face {
  font-family: 'FontAwesome';
  src: /*local('FontAwesome'), */url(./fonts/FontAwesome/fontAwesome5ProRegular400.otf) format('opentype');
}

@font-face {
  font-family: 'FontAwesomeLight';
  src: /*local('FontAwesomeLight'), */url(./fonts/FontAwesome/fontAwesome5ProLight300.otf) format('opentype');
}

@font-face {
  font-family: 'FontAwesome6';
  src: /*local('FontAwesome6'), */url(./fonts/FontAwesome/fontAwesome6FreeRegular400.otf) format('opentype');
}

@font-face {
  font-family: 'FontAwesome6Solid';
  src: /*local('FontAwesome6Solid'), */url(./fonts/FontAwesome/fontAwesome6FreeSolid900.otf) format('opentype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; 
}

p {
  font-size: 1.4rem;
  color: var(--coalLight);
}

a {
  color: inherit;
  font-family: "RobotoCondensedBold";
}

a:hover {
  opacity: 0.6;
}

h2 {
  font-family: TruenoSBd !important;
  font-size: 2.5rem;
  color: var(--coal);
}

.mainBlackContainer {
  border-radius: 20px;
  width: calc(70vw + 100px);
  height: calc(70vh + 70px);
  max-width: 1200px;
  max-height: 700px;
  box-sizing: border-box; 
  padding: calc(0.5vw + 5px);
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(123.54deg, #131313 -1.2%, #676767 94.45%);
  box-shadow: 3px 1px 84px 0px rgba(0,0,0,1);
  -webkit-box-shadow: 3px 1px 84px 0px rgba(0,0,0,1);
  -moz-box-shadow: 3px 1px 84px 0px rgba(0,0,0,1);
}

.mainBlackContainerLoggedIn {
  padding: 0;
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  max-width: none;
  max-height: none;
}

.mainWhiteContainer {
  border-radius: 20px;
  width: 83%;
  height: 100%;
  flex-direction: column;
  position: relative;
  background: linear-gradient(354deg, rgba(247,247,247,1) 0%, rgba(223,223,223,1) 40%, rgba(255,255,255,1) 80%);
  box-shadow: inset 1 0 20px #959687;
  box-shadow: 0px 3px 17px #000000;
}

.mainWhiteContainerLoggedIn {
  border-radius: 0;
}

.mainWhiteContainerContent {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  transition: opacity 0.2s linear;
  overflow-y: scroll;
}

.mainWhiteContainerContentHidden {
  opacity: 0;
}

.contentGrid {
  padding: 0.5rem 2rem 2rem 2rem;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 1.2rem;
}




.errorPrompt {
  font-family: "Roboto";
  font-size: 1.3rem;
  font-weight: medium;
  color: var(--error);
}

/* Override the Google Maps zoom controls */
.gm-style .gmnoprint,
.gm-style .gm-style-mtc {
  flex-direction: column !important; /* Ensure zoom buttons stack vertically */
  align-items: center !important; /* Center align the buttons */
}

/* Specific styling for the + and - buttons */
.gm-style .gmnoprint > div {
  display: block !important; /* Ensure buttons are block-level */
  margin: 5px 0 !important; /* Add some spacing between the buttons */
}

.gm-style .gmnoprint  > div[style*="background-color: rgb(255, 255, 255)"] {
  background-color: rgba(0, 0, 0, 0) !important; /* Change to your desired color */
/* Further customizations */
}

div[style*="background-color: rgb(230, 230, 230)"] {
  background-color: rgba(0, 0, 0, 0) !important; /* Change to your desired color */
/* Further customizations */
}

/* Rectangle 1194 */



.gm-style .gmnoprint > div button {
  width: 40px !important; /* Set a specific width */
  height: 40px !important; /* Set a specific height */
  background: #383838 !important;
  box-shadow: 0px 0px 13.4px rgba(255, 255, 255, 0.23), inset 0px 0px 15.6px #000000 !important;
  border-radius: 4px !important; /* Round the corners */
  border: 0px solid #ccc !important; /* Add a border */
  cursor: pointer;
  color:  #B0E000 !important; /* Change the background color */
}

.gm-style .gmnoprint > div button {
  width: 40px !important; /* Set a specific width */
  height: 40px !important; /* Set a specific height */
  background-color: #383838 !important; /* Change the background color */
  border-radius: 4px !important; /* Round the corners */
  border: 0px solid pink !important; /* Add a border */
  cursor: pointer;
  color:  #B0E000 !important; /* Change the background color */
}

.gm-style .gmnoprint > div button:hover {
  background-color: #e0e0e0 !important; /* Change background on hover */
  box-shadow: 0px 0px 13.4px rgba(255, 255, 255, 0.23), inset 0px 0px 15.6px #fff !important;
}

.gm-control-active>img:nth-child(1) {
  filter: invert(1) brightness(2);
}

.gm-style .gmnoprint > div {
 background-color: none;
}


@media only screen and (max-width: 1000px) { 
  .mainWhiteContainer {
    width: 91%;
  }
}


@media only screen and (min-width: 1440px) { 
  .mainBlackContainerLoggedIn {
    padding: 0;
    border-radius: 20px;
    width: 100vw;
    height: 100vh;
    max-width: 1300px;
    height: 800px;
    max-height: 90vh;
  }
  .mainWhiteContainer {
    border-radius: 20px;
    height: 97%;
    margin-right: 1.5vh;
  }
}

#html5-qrcode-button-camera-stop{
  visibility: hidden;
  display: none;
}

video{
  object-fit:none;
    /* overflow-clip-margin: none; */
    overflow: visible;
}

#html5-qrcode-button-camera-permission {
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: var(--leafLight);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 12px rgba(0, 0, 0, 0.12), inset 0px 0px 18px #149100;
  color: white;
  font-family: "RobotoCondensedBold";
  font-size: 20px;
  z-index: 999999999;
}

.contentGridNoBg {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  height: 100%;
}

.leftColumn {
  width: 50%;
}

.rightColumn {
  width: 30%;
  flex-direction: column;
  gap: 15px;
}

.subscriptionDetailsContainer {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.gaugeSeparatorLine {
  width: 100%;
  height: 1px;
  background-color: var(--greyLight);
}


.charpointsContainer {
  background-color: var(--coal);
  color: white;
  font-family: "TruenoBd";
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 80px;
  height: 80px;
  border-radius: 30px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.points {
  font-size: 10px;
  margin-left: 2px;

}
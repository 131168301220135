.container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

}

.contentGridNoBg {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.addressRow {
  width: 100%;
}

.detailsWrapper{
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.detailsRow{
  margin-top: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.5rem;
  color: var(--coalVeryLight);
}

.detailsRowSection {
  width: 50%;
  padding-left: 0.6rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 1px solid var(--greyLight);
  font-family: "RobotoCondensed";
}

.detailsRowSectionFullWidth {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  min-height: 10rem;
  font-family: "Roboto";

}

.detailsRowSectionHeader {
  font-weight: 600;
  margin-bottom: 0.7rem;
  color: var(--coalLight);
  font-family: "RobotoCondensed";
}

.accountDetailsContainer {
  align-items: flex-start;
  flex-direction: row;
  gap: 50px;
  padding: 1.5rem 1rem 2rem 1rem;
  width: 100%;
  position: relative;
}

.accountDetailsColumn {
  flex-direction: column;
  gap: 10px;

}

.inputRow {
  width: 250px;
}

.buttonRow {
  margin: 10px 0;
  width: 500px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.iconEdit {
  font-family: "FontAwesomeSolid";
  position: absolute;
  right: 0px;
  top: -30px;
  cursor: pointer;
}

.iconEdit:hover {
  opacity: 0.7;
}
.applicationCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  /* background: var(--greyLight); */
  border: 1px solid var(--greyLight); 
  width: 100%;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  min-height: 10rem;
}

.applicationHeaders {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "TruenoSBd";
  color: var(--coalVeryLight);
  border-bottom: 1px solid var(--greyLight);
  padding-bottom: 0.4rem;
}


.applicationTags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
}


.placeholderApplicationTags {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
}

.tag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 1rem;
  background: var(--grey);
  color: white;
  font-family: "RobotoCondensedMed";
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}
.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.contentGrid {
  width: 100%;
  margin-left: 0px;
  position: relative;
}

.headerRow {
  width: 100%;
  margin-top: -0.7rem;
  height: 3.4rem;   
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.6rem;
  font-family: "TruenoRg";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
}

.headerFilter {
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

.headerFilter:hover {
  opacity: 0.6;
}

.headerFilterSelected {
  height: calc(100% - 2px);
  color: var(--coalLight);
  border-bottom: 2px solid var(--coalLight);
}

.orderTable {
  width: 100%;
  margin-top: 1rem;
  border-spacing: 0 1.2rem;
}

.orderHeaderRow{
  text-align: left;
}

th:first-child{
  padding-left: 1rem;
}

td:first-child{
  padding-left: 1.4rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -webkit-border-radius: 1rem 0 0 1rem;
}
td:last-child{
  -moz-border-radius: 0 1rem 1rem 0;
  -webkit-border-radius: 0 1rem 1rem 0;
}



.tableRow{
  
  width: 100%;
  height: 5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: left;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
}

.dataBold {
  font-weight: 700;
}

.exp {
  font-size: 0.8rem;
  position: relative;
  bottom: 0.5rem;
}


.statusDelivered {
  color: var(--leaf);
  font-weight: 700;
}

.statusUpcoming {
  color: var(--coal);
  font-weight: 700;
}

.statusCancelled {
  color: var(--error);
  font-weight: 700;
}

.statusIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 0.4rem;
}


.newZoneButtonContainer {
  width: 140px;
  position: absolute;
  top: -80px;
  right: 70px;
}

.geometrySettingsContainer {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.toggleSwitchContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "RobotoCondensedMed";
}

.resetButtonContainer {
  height: 100%;
  padding: 10px 0 !important; 
  width: 100px;
}

.contentWrapper {
  padding: 0rem 1rem;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.detailsRow {
  width: 100%;
  gap: 3rem;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}

.halfRow {
  padding-right: calc(50% + 1.5rem);
}

.buttonRow {
  margin-top: 2rem;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}

.container {
  width: 100%;
  opacity: 1;
}

.zoneContainer {
  width: 100%;
  /* display: flex; */
  opacity: 1;
  padding: 0px 20px 20px 20px;
}
.ZoneDetailsContainer {
  display: flex;
  flex-direction: column;
}

.ZoneDetailsGrid {
  display: flex;
  flex-direction: column;
}

.ZoneDetailsRow {
  display: flex;
  flex-direction: column;
}


.applicationsSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  min-height: 100%;
  margin-right: 1.4rem;
}

.zoneDetailsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0 0;
  height: 2.8rem;
  border-bottom: 1px solid var(--greyLight);
}

.editIcon {
  font-family: "FontAwesomeSolid";
  font-size: 1.6rem;
  cursor: pointer;
  margin-right: 1.2rem;
}

.editIcon:hover {
  opacity: 0.5;
}


.deleteZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 300px;
}

.deleteInput {
  width: 100%;
}





.fieldInfoSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 350px;
  padding: 0 1rem;

}

.fieldInfoGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.applicationTags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
}

.placeholderApplicationTags {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* ✅ Allows items to wrap onto a new line */
  gap: 0.5rem;
}

.tag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 1rem;
  background: var(--coal);
  color: white;
  font-family: "RobotoCondensedMed";
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}

.contentContainer {
  width: 100%;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.placeholder {
  width: 100%;
}

.noZoneSelected {
  font-size: 1.6rem;
  margin-top: 2rem;
  width: 100%;
}

.placeholderTag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 1rem;
  background: var(--grey);
  color: white;
  font-family: "RobotoCondensedMed";
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
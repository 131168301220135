.blackContainerContent {
  width: 60%;
  height: 100%;
  position: relative;
  opacity: 1;
  transition: width 0.5s;
  transition-delay: 0.2s;
}

.blackContainerContentGoToSignUp {
  width: 28%;
}

.blackContainerContentGoToForgotPassword {
  opacity: 0;
  width: 0%;
}

.bbbLogoWhiteContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem 0 0 2.5rem;
}

.bbbLogoWhite {
  max-width: 3.7rem;
}

.bbbLogoText {
  font-family: "TruenoBd";
  font-weight: 700;
  color: white;
  font-size: 1.4rem;
  margin-left: 0.4rem;
  margin-bottom: 0.1rem;
}

.welcomeMessageContainer {
  height: fit-content;
  align-items: flex-start;
  width: 75%;
  opacity: 1;
  transition: opacity 0.2s;
}

.welcomeMessageContainerGoToSignUp {
  opacity: 0;
}

.welcomeMessageContainerGoToForgotPassword {
  opacity: 0;
}

.welcomeHeader {
  display: block;
  font-family: "TruenoSBd";
  font-weight: 600;
  font-size: 3.8rem;
  color: var(--leaf);
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25), 3px 4px 4px rgba(0, 0, 0, 0.47);
}

.welcomeText {
  color: var(--grey);
  font-size: 1.7rem;
  display: block;
}

.headerGreen {
  color: var(--grass);
}

.textLink {
  text-decoration: underline;
  font-weight: bold;
  color: var(--grey);
  cursor: pointer;
}

.textLink:hover {
  opacity: 0.7;
}

.welcomeMessageTextContainer {
  flex-direction: column;
  align-items: flex-start;
}


.mainWhiteContainer {
  width: 40%; /*make pixel based?*/
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  gap: 0.5rem;
  transition: width 0.5s ease;
  transition-delay: 0.2s;
}

.mainWhiteContainerGoToSignUp {
  width: 70%;
} 

.mainWhiteContainerGoToForgotPassword {
  width: 100%;
} 

.loginContent {
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
  max-width: 340px;
  opacity: 1;
  transition: opacity 0.2s;
}

.loginContentHidden {
  opacity: 0;
}

.logoContainer {
  height: 20%;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  z-index: 3;
}

.bbbLogo {
  margin-top: 1vh;
  height: 100%;
}


.greenCirclesWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  pointer-events: none;
  overflow: hidden;
}

.greenCircle1 {
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: 3.5vw;
  border-radius: 0px 20px 0px 0px;
  transition: all 0.4s;
}

.greenCircle1Hidden {
  max-width: 0vw;
}

.greenCircle2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-width: 3.5vw;
  border-radius: 0px 20px 0px 0px;
  transform: rotateZ(180deg);
  transition: all 0.4s ease;
}

.greenCircle2Hidden {
  max-width: 0vw;
}

.loginHeader {
  color: var(--coal);
}

.loginOptionsContainer {
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
}

.forgotPassword {
  color: var(--coalLight);
  font-family: "RobotoCondensedBold";
  font-size: 1.3rem;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.forgotPassword:hover {
  opacity: 0.7;
}

.errorPromptContainer {
  position: relative;
  top: 1rem;
  height: 0;
}


@media only screen and (min-width: 1440px) { 
  .mainWhiteContainer {
    margin-left: 1.5vh;
  }
}
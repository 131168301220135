.charpointsContainer {
    background-color: #70C464;
    color: white;
    border-radius: 7px;
    padding: 10px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -8px;
    padding-top: 8px;
  }
  
  .charpointsHeader {
    display: flex;
    font-family: "TruenoBd";
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    font-size: 15px;
    margin-top: -9px;
    margin-bottom: 0px;
  }

  .charpointsHeaderLine {
    margin-top: -2px;
    margin-bottom: 3px;
    height: 2px;
    width: 100%;
    background-color: var(--grassLight);
  }

  
  .fontAwesomeSubstitute {
    max-height: 1.4rem;
    margin-right: 7px;
  }

  .charpointsTitle {
    font-size: 15px !important;
    font-weight: bold;
  }

  .charpointsFontAwesome {
    font-family: "FontAwesomeSolid";
    font-weight: 300;
    margin-right: 7px;
  }
  
  .charpointsPrice {
    font-size: 16px;
    font-weight: bold;
    color: #E3F5E1;
  }
  
  .currency {
    font-size: 12px;
    margin-right: 1px;
  }
  .charpointsBody {
    font-size: 14px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "RobotoCondensedMed";
    color:  #E3F5E1 !important;

  }
  


  .charpointsTotal {
    margin-top: 0px;
    font-size: 13px;
    font-weight: bold;
    color: var(--grassLight);
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
  }
  
  .totalText {
    font-size: 15px !important;
    font-family: "RobotoCondensedBold";
    width: 50%;
    display: flex;
    justify-content: flex-end;
    }

  .charpointsCalculation {
    display: flex;
    align-items: center;
    border: 2px solid var(--grassLight);
    border-radius: 4px;
    padding: 0px 3px;

  }

  .charpointsCalcContainer{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
    }
  
  .charpointsInput {
    width: 50px;
    padding: 6px;
    font-size: 16px;
    border: 2px solid #F1FFCC;
    border-radius: 8px;
    margin-right: 8px;
    text-align: center;
  }

  .perBag {
    color: var(--grassLight);
    font-size: ;

  }

  .totalText {
    color: var(--grassLight);
    font-size: 12px;
  }
  .totalText2 {
    color: #fff;
    font-size: 11px;
    margin-right: 5px;
  }

  
  @media (max-width: 768px) {

  
    .charpointsTitle {
      font-size: 20px;
    }
  
    .charpointsPrice {
      font-size: 16px;
    }
  
    .charpointsTotal {
      font-size: 16px;
      display: flex;
      align-items: bottom;
    }
  
    .charpointsInput {
      width: 40px;
      padding: 4px;
      font-size: 14px;
    }
  }

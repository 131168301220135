.notificationSettingsContainer {
    flex-direction: column;
    padding: 1.5rem 1rem 0rem 1rem;
    width: 100%;
  }
  
  .inputRow {
    width: 100%;
    height: 4rem;
    margin-bottom: 1rem;
    justify-content: space-between;
  }

  .buttonRow {
    width: 100%;
    margin-top: 0.8rem;
    gap: 2rem;
  }

  .labelContainer {
    width: 20rem;
    justify-content: flex-start;
    color: var(--coalLight);
    font-family: "RobotoCondensedMed";
    font-size: 1.6rem;
  }

  .inputContainer {
    width: 21rem;
    justify-content: flex-end;
  }


.tooltip {
    font-family: "FontAwesomeLight";
    color: var(--grey);
    position: relative;
    cursor: help;

}

.hoverArea {
    position: absolute;
    bottom: -1rem;
    right: -0.6rem;
    width: 2.8rem;
    height: 2.8rem;
}

.bubble {
    position: absolute;
    background: var(--coal);
    padding:  1rem 1.5rem 0rem 1.5rem;
    border-radius: 0.7rem;
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 1.4rem;
    color: white;
    letter-spacing: -0.015em;
    transition: opacity 0.2s linear;
    user-select: none;
    pointer-events: none;
    box-shadow: 0px 3.38126px 18.45314px rgba(0, 0, 0, 0.5), inset 0px 0px 4px rgba(245, 245, 245, 0.45);
}

.bubbleHidden {
    opacity: 0;
}

.bottomLeft {
    top: 2rem;
    right: -3.2rem;
}

.arrowBottomLeft {
    position: absolute;
    top: -1rem;
    right: 3rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1.3rem solid var(--coal);
}

.bottomRight {
    top: 2rem;
    left: -4.2rem;
}

.arrowBottomRight {
    position: absolute;
    top: -1rem;
    left:1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1.3rem solid var(--coal);
}


.topLeft {
    bottom: 2rem;
    right: -1.2rem;
}

.arrowTopLeft {
    position: absolute;
    bottom: -1rem;
    right:1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1.3rem solid var(--coal);
}

.topRight {
    bottom: 2rem;
    left: -3.2rem;
}

.arrowTopRight {
    position: absolute;
    bottom: -1rem;
    left: 1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1.3rem solid var(--coal);
}

.container {
    width: 390px; 
    height: 240px;
    display: flex;
    align-items: flex-start;
}

.columnLeft {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: flex-start;
    align-items: flex-start;
}

.farmerRow {
    font-family: "RobotoCondensedBold";
    font-size: 2rem;
}

.status {
    font-family: "RobotoCondensedMed";
    color: var(--grass);
    margin-bottom: 0.8rem;
}

.qrContainer {
    height: 170px;
    width: 150px;
    background-color: black;
    border: 5px solid black;
    border-radius: 10px;
}

.columnRight {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 14px;
    padding: 7px 10px;
    font-family: "TruenoSBd";
    font-size: 1.2rem;
}

.rowFull {
    background-color: var(--coalLight);
}

.fieldName {
    font-family: "RobotoCondensedMed";
}

.openInOrders {
    background-color: pink;
}

.fontAwesome {
    font-size: 12px;
    font-family: "FontAwesomeSolid";
}


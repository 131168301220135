.paymentPlansContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 0 2rem 0;
}

.paymentPlanContainer {
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 0rem 0.7rem 0rem;
    width: 31%;
    height: 100%;
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    box-shadow: 1.8052px 2.40693px 4.81386px rgba(0, 0, 0, 0.25), inset 0px 0px 9.62771px rgba(0, 0, 0, 0.08);
    border-radius: 4.21212px;
}

.paymentPlanMainContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.paymentPlanNamePrice {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.planName {
    color: #fff;
    font-family: 'TruenoSBd';
    font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
    letter-spacing: 0.035em;  
    background: var(--coal);
    border-radius: 4.21212px 4.21212px 0 0;
    padding: 0.9rem 0;
    width: 100%;
}

.planPrice {
    color: var(--leaf);
    font-family: 'TruenoSBd';
    font-weight: 600;
    font-size: 3.1rem;
    letter-spacing: -0.02em;
    margin: 0.4rem 0;
}

.pricePound {
    font-size: 1.4rem;
    margin-right: 0.3rem;
}

.priceDash {
    color: var(--grass);
    margin: 0 0.2rem 0 0.2rem;
}

.pricePerTime {
    color: var(--grass);
    font-size: 1.75rem;
}

.checkIcon{max-width: 2rem;
}

.planDescription {
    text-align: center;
    margin: 1rem 0 1rem 0;
    padding: 0 1.5rem;
    color: var(--coalVeryLight);
}

.buttonWrapper {
    width: 80%;
}

.lineSeparator {
    width: 86%;
    height: 1px;
    background-color: var(--greyLight);
    margin: 1rem 0 1rem 0;
}

.perksContainer {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.perksHeader {
    font-family: "RobotoCondensedBold";
    font-size: 1.6rem;
    color: var(--coalLight);
    margin: 1rem 0 2rem 0;
}

.perkRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem;
}

.checkIcon {
    margin-right: 0.5rem;
}

.perkText {
    font-family: "Trueno";
    color: var(--coalVeryLight);
    font-size: 1.3rem;
    margin: 0 0 9px 0;
}

.recommendedPlanContainer {
    display: flex;
    flex-direction: column;
    background: var(--leaf);
    color: #fff;
    font-weight: 600;
    width: 31%;
    height: calc(100% + 4rem);

    /* font-size: 1.3rem;
    text-align: center;
    letter-spacing: 0.035em;
    border-radius: 4.21212px 4.21212px 0 0;
    padding: 0.9rem 0;
    width: 100%; */
}
.recommendedPlanContent {
    width: calc(100% - 5px);
}
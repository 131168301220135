.detailsTableContainer {
    margin-top: 2.2rem;
    width: 100%;
    flex-direction: column;
}

.rowDark {
    justify-content: space-between;
    border-radius: 2rem;
    width: 100%;
    height: 4rem;
    padding: 1.5rem;
    background: var(--greyVeryLight);
    box-shadow: 0px 0px 8px #FFFFFF;
}

.rowLight {
    justify-content: space-between;
    border-radius: 1.5rem;
    width: 100%;
    height: 4rem;
    padding: 1.2rem;
}

.fieldName {
    font-family: "RobotoCondensedMed";
    color: var(--coalLight);
    /* font-weight: bold; */
}

.fieldValue {
    font-size: 1.3rem;
    font-family: "TruenoSbd";
    letter-spacing: -0.02em;
}


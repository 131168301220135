.userDetailsContainer {
    flex-direction: column;
    padding: 1.5rem 1rem 0rem 1rem;
    width: 100%;
    max-width: 35rem;
  }
  
  .inputRow {
    width: 100%;
    margin-bottom: 1rem;
  }

  .buttonRow {
    width: 100%;
    margin-top: 0.8rem;
    gap: 2rem;
  }
.settingsContainer {
  width: 220px;
  /* border: 1px solid var(--grey); */
  padding: 9px;
  background: #fff;
  border-radius: 10px;
  margin-top: 0px;
  margin-right: -10%;
  position: sticky;
  top: 10px;
  right: 40px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;

  box-shadow: 1.8052px 2.40693px 4.81386px rgba(0, 0, 0, 0.25), inset 0px 0px 9.62771px rgba(0, 0, 0, 0.08);
}



.contentContainer {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dottedLine {
  border: 1px dashed var(--grey);
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6mm;
  margin-bottom: 10px;
  margin-left: 10mm;
}

.imagesContainer {
min-width: 900px;
}

.charcodesContainer {
  background-color: "blue";
  border-radius: 20px;
  display: flex;
  /* grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr); */
  gap: 6mm; /* Adjust gap as needed */
  padding: 8mm 10mm; /* Margin for content */
  transform: scale(0.7);
  width: 1200px;
  height: 1697px;
  position: relative;
}

.bbbLogo {
  position: absolute;
  top: 40px;
  right: 30px;
  max-width: 100px;
}

.settingsHeader {
  font-family: "RobotoCondensedMed";
  font-size: 18px;
  color: var(--coalLight);
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.buttonsContainer {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.placeholder {
  grid-column: 1 / -1;
  width: 600px;
  height: 200px;
  font-size: 16px;
  font-family: "RobotoCondensedMed";
  color: var(--coalLight);
}

.charcodesPerPage {
  width: 100%;
  opacity: 0.6;
  pointer-events: none;
}

.charcodeContainer {
  margin-top: -70px;
  width: 695px;
  height: 842px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.charcodeQrOnlyContainer {
  margin-top: -70px;
  width: 795px;
  height: 1042px;
  transform: scale(0.7);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-family: 
}

.imagesContainer {

}

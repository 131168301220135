.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}


.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}


.contentWrapper {
  padding-top: 3rem;
  flex-direction: column;
  width: 70%;
}

.detailsRow {
  width: 100%;
  gap: 3rem;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}

.buttonRow {
  margin-top: 2rem;
  width: 100%;
  gap: 3rem;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}
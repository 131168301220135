.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}


.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.mainWhiteContainer {
  width: 100%;
  margin: 0;
}
            
.logoContainer {
  height: 20%;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  z-index: 3;
}

.bbbLogo {
  margin-top: 1vh;
  height: 100%;
}

.greenCirclesWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  pointer-events: none;
  overflow: hidden;
}

.greenCircle1 {
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: 3.5vw;
  border-radius: 0px 20px 0px 0px;
  transition: all 0.4s;
}

.greenCircle1Hidden {
  max-width: 0vw;
}


.greenCircle2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-width: 3.5vw;
  border-radius: 0px 20px 0px 0px;
  transform: rotateZ(180deg);
  transition: all 0.4s ease;
}

.greenCircle2Hidden {
  max-width: 0vw;
}


@media only screen and (min-width: 1440px) { 
  .mainBlackContainerLoggedIn {
    padding: 0;
    border-radius: 20px;
    width: 100vw;
    height: 100vh;
    max-width: 1300px;
    height: 800px;
    max-height: 90vh;
  }
  .mainWhiteContainer {
    border-radius: 20px;
    height: 97%;
    margin: 0 1.5vh 0 1.5vh;
  }
}
.container {
    flex-direction: column;
}

.label {
    font-family: "RobotoCondensed";
    font-weight: 800;
    user-select: none;
    margin-bottom: 0.2rem;
    color: var(--);
}

.incrementerContainer {
    width: 10rem;
    height: 3rem;
}

.button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.22rem;
    color: white;    
    font-family: 'Trueno';
    font-size: 2.3rem;
    font-weight: 900;
    cursor: pointer;
    user-select: none;
}

.button:hover {
    margin-top: -0.15rem;
}

.button:active {
    margin-top: 0.15rem;
    box-shadow: none;
}


.minusButton {
    box-shadow: 1px 0.7px 1.7px rgba(0, 0, 0, 0.25), inset 0px -1px 4px rgba(11, 137, 207, 0.4);
    background: var(--grass);
    padding-bottom: 0.2rem;

}

.plusButton {
    background: var(--grass);
    font-weight: 600;
    background: #34B61F;
    box-shadow: 0px 0px 4.46892px rgba(0, 0, 0, 0.25), inset 0px 0px 5.95856px rgba(0, 0, 0, 0.25);
}

.valueInput {
    width: 4rem;
    height: 2.5rem;
    background: white;
    border: 1px solid var(--grass);
    margin: 0 0.3rem 0 0.3rem;    
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: -0.05rem;
    color: #344236;
    text-align: center;
    padding-left: 0rem;
    border-radius: 0.3rem;
}


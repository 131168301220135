.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px 30px;
}

.calendarMainContainer {
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-left: 2rem;
  padding-bottom: 1.2rem;
}

.headerRow {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.3rem;
  margin-bottom: 1.2rem;
}

.header{
  margin: 0;
  padding: 0;
  font-family: "Trueno";
  font-size: 2.1rem;
  font-weight: normal;
  color: var(--coalLight);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -0.1rem;
}

.dateLabel {
  font-family: "RobotoCondensed";
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--coalLight);
  margin-right: 10px;
}

.dateFilterContainer {
  margin-right: 3rem;
}

.date {
  padding: 0.5rem 1rem;
  box-sizing: border-box;

  background: #FFFFFF;
  box-shadow: 0px 2px 4.5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 1px solid var(--greyLight);
  user-select: none;
  font-family: "RobotoCondensed";
  font-size: 15px;
  color: var(--coalVeryLight);
}

.sitesInventoryContainer {
  position: absolute;
  top: -50px;
  right: 0px;
  display: flex;
  gap: 20px;
}

.siteContainer {
  border: 1px solid var(--coalVeryLight);
  border-radius: 20px;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  gap: 13px;
  letter-spacing: 0rem;
}



.siteLeftColumn {
  padding: 0.2rem 1.4rem;
  border-radius: 20px 0 0 20px;
  background-color: var(--coal);
  font-family: "RobotoCondensed";
  font-weight: bold;
  color: #fff;
}
.siteRightColumn {
  padding-right: 1rem;
  border-radius: 20px 0 0 20px;
  font-family: "RobotoCondensed";
  font-weight: bold;
}

.inventoryValue {
  color: var(--greyDark);
}

.calendarGridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 columns with equal width */
  gap: 10px; /* Add gap between grid items */
}

.weekSlot {
  min-height: 280px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 25px;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  border: 1px solid #DADADA;
  box-shadow: 2.03501px 4.07002px 5.08753px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 9px;
  height: 100%;
}

.weekSlotHeaderRow {
  width: 100%;
  padding: 0.4rem 1.5rem;
  margin-bottom: 1.2rem;
  background-color: var(--coal);
  color: #fff;
  border-radius: 9px 9px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.weekSlotHeaderRow:hover {
  opacity: 0.8;
}

.weekSlotDate {
  font-family: "TruenoSBd";
  font-size: 1.3rem;
}

.weekSlotOpenIconContainer {
  font-family: "FontAwesome6Solid";
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.3rem;
}

.weekSlotOpenIconContainer:hover {
  opacity: 0.7;
}

.weekSlotContent {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.weekSlotInventoryContainer {
  width: 100%;
  padding: 1rem 1rem 0 1rem; 
  display: flex;
  flex-direction: column;
}

.weekSlotInventoryHeaderRow {
  color: var(--coalLight);
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem 1.2rem;
  height: 2rem;
  font-family: "RobotoCondensed";
}

.weekSlotInventoryHeaderColumn1 {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
}

.weekSlotInventoryHeaderColumn2 {
  width: 20%;

}

.weekSlotInventoryHeaderColumn3 {
  width: 20%;
}

.weekSlotInventoryRow {
  color: var(--coalVeryLight);
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem 1.2rem;
  height: 2.2rem;
  background: var(--greyVeryLight);
  border-radius: 10px;
  font-family: "RobotoCondensed";
  font-weight: medium;
}

.weekSlotInventoryRowTransparent {
  background: inherit;
}

.weekSlotInventoryColumn1 {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.weekSlotInventoryColumn2 {
  width: 20%;
  font-weight: bold;
}

.weekSlotInventoryColumn3 {
  width: 20%;
  font-weight: bold;
}

.weekSlotLine {
  background: var(--greyLight);
  height: 1px;
  width: 100%;
  margin: 10px 0 10px 0;
}

.eventsContainer {
  width: 100%;
  padding: 0.2rem 1.2rem;
  font-family: "RobotoCondensed";
  font-size: 9px;
  color: var(--coalVeryLight);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  height: 100%;
}

.eventsContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;

}

.eventRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  border: 1px solid #DADADA;
  box-shadow: 2.03501px 4.07002px 5.08753px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 0.5rem 1.2rem;
  height: 2.2rem;
  cursor: pointer;
}

.eventRow:hover {
  opacity: 0.6;
}

.eventColumn1 {
  width: 55%;
  display: flex;
  justify-content: flex-start;
}

.eventColumn2 {
  width: 30%;
}

.eventColumn3 {
  width: 15%;
}

.iconEvent {
  font-family: "FontAwesomeSolid";
  color: var(--coalLight);
  margin-right: 0.5rem;
}

.eventDay {
  margin-right: 0.3rem;
  font-weight: bold;
}

.addEventButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--grey);
  border-radius: 15px;
  padding: 0.5rem 1.2rem;
  height: 2.2rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  user-select: none;
}

.addEventButton:hover {
  transform: scale(1.02);
}






/* 
.date::highlight {
  background-color: grey !important;
} */


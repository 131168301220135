.mainContainer {
    width: 100%;
    min-height: 6rem;
    position: relative;
}

.mainContainerLabelTop {
    flex-direction: column;
    align-items: flex-start;
}

.textInput {
    font-family: "RobotoMed";
    font-size: 1.5rem;
    color: var(--coalVeryLight);
    box-sizing: border-box;
    padding: 1.6rem;
    width: 100%;
    height: 3.3rem;
    border-radius: 1.5rem;
    border: 0px solid green;
    box-shadow: 0px 3.45713px 8.64283px rgba(0, 0, 0, 0.1);
}

.textInput::placeholder {
    color: var(--greyLight);
}

.textInput:focus {
    border: 2px solid var(--grey);
    padding: calc(1.6rem - 2px);
    outline: none;
}

.labelContainer{
    font-family: "RobotoCondensedMed";
    color: var(--coalLight);
    font-size: 1.4rem;
    padding: 0.3rem;
    margin-right: 0.5rem;
}

.labelIcon{
    font-family: "FontAwesomeSolid";
    margin-right: 0.7rem;
}

.customIcon {
    max-height: 1.4rem;
    margin-left: -7px;
    margin-right: 6px;
}  

.password {
    letter-spacing: 0.14rem;
    font-weight: 900;
}

.password::placeholder {
    letter-spacing: 0;
    font-weight: normal;
}

.highlightedLabel {
    color: var(--error) !important;
}

.highlightedInput {
    border: 2px solid var(--error);
    padding: calc(1.6rem - 2px);

}

.passwordDisplayIcon {
    position: absolute;
    right: 1rem;
    bottom: 0.9rem;
    max-width: 2rem;
    opacity: 0.2;
    cursor: pointer;
}

.passwordDisplayIcon:hover {
    opacity: 0.7;
}

.fullContainer {
  width: 100vw;
  height: 100vh;
  background: #2A2A2A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blackContainer {
    background: linear-gradient(123.54deg, #444444 -1.2%, #676767 94.45%);
    box-shadow: 11.8159px 11.8159px 39.3862px #000000;
    border-radius: 32.1108px;
    width: 90vw;
    max-width: 535px;
    height: 90vh;
    max-height: 700px;
    position: relative;
}


.container {
  width: 85vw;
  height: 86vh;
  max-width: 500px;
  max-height: 665px;
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;
  background: linear-gradient(354deg, rgba(247,247,247,1) 0%, rgba(223,223,223,1) 40%, rgba(255,255,255,1) 80%);
  box-shadow: inset 1 0 20px #959687;
  box-shadow: 0px 3px 17px #000000;
  border-radius: 20px;
  padding: 30px 28px;
}

.logoContainer {
  position: absolute;
  z-index: 99;
  top: -10px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #FFFFFF;
}

/* bbb_logo_standard_no_text 1 */



.logo {
  max-width: 60px;
  margin-top: 4px;
  left: 15px;
  filter: drop-shadow(0px 6.07027px 6.07027px rgba(0, 0, 0, 0.25));
}

.circle1 {
  position: absolute;
  max-width: 35px;
  bottom: 0px;
  left: -2px;
  transform: rotateZ(180deg);
}

.circle2 {
  position: absolute;
  max-width: 35px;
  top: 0px;
  right: -2px;
  transform: rotateZ(0deg);
}

.header {
  margin-top: 5px;
  font-family: "TruenoBd";
  /* font-weight: medium; */
  font-size: 17px; /*18px;*/
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: var(--coal);
  margin-bottom: -3px;

}


.description {
  width: 100%;
  font-size: 12px;
  text-align: left;
  color: var(--coalLight);
  margin-bottom: 4px;
}

.sectionHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--coalLight);
  font-family: "RobotoCondensedBold";
  font-size: 14px;
}

.sectionHeaderMain {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  margin: 0;
  padding: 0;
}

.sectionFarmer {
  margin-top: 6px;
  margin-bottom: 7px;
  font-weight: medium;
}

.fontAwesomeSubstitute {
  max-height: 1.4rem;
  margin-right: 7px;
}


.headerIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 5px;
  height: 1.4rem;
  overflow: visible;
  line-height: 1;
}

.collapseIcon {
  font-family: "FontAwesomeSolid";
}

.charcodesContainer {
  max-height: 190px;
  /* background-color: pink; */
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to next line */
  justify-content: flex-start;
  gap: 6px;
  margin-bottom: 20px;  
  opacity: 1;
  transition: all 0.2s linear;
  /* pointer-events: all; */
  overflow-Y: scroll;
  /* z-index: 999; */
  -webkit-overflow-scrolling: touch;
  /* background-color: pink; */
  padding: 2px 0;
}

.chromeLocationContainer {
  height: 300px;
  overflow-y: scroll;
}

.androidLocationContainer {
  height: 200px;
  overflow-y: scroll;
}

.chromeLocation {
  width: 100%;
  margin-bottom: 6px;
  margin-top: 6px;


}

.chromeText {
  margin-bottom: 10px;

}

.charcodesContainerBagging {
  margin-bottom: 0px !important;
}

.shoppingBagSelector {
  max-height: 11px;
  opacity: 0.8;
  padding: 2px;
}

.storageSelector {
  max-height: 11px;
  opacity: 0.8;
  padding: 2px;
  margin-right: 3px;
}


.charcodesCollapsed {
  max-height: 0px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  gap: 0px!important;
  margin-bottom: -0px !important;
}

.hideBags {
  opacity: 0;
  pointer-events: none;
}

.buttonWrapper {
  width: 100%;
  margin-top: -5px;
  height: 40px;
}

.headerCharcode {
  font-family: "RobotoCondensedMed";
  letter-spacing: -0.2px;
  font-size: 13px;
  padding: 2px 6px;
  border-radius: 20px;
  color: #fff;
  background-color: var(--coalLight);
  box-shadow: 1.87509px 3.16679px 4.3336px rgba(0, 0, 0, 0.13), inset 0px 0px 36.6672px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.headerCharcodeAlreadyComplete {
  background-color: var(--leaf);
}

.headerCharcodeCheckbox {
  font-family: "RobotoCondensedMed";
  letter-spacing: -0.2px;
  font-size: 12px !important;
  padding: 2px 0px;
  border-radius: 20px;
  /* color: #fff; */
  background-color: var(--greyVeryLight);
  box-shadow: 1.87509px 3.16679px 4.3336px rgba(0, 0, 0, 0.16), inset 0px 0px 36.6672px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  /* max-width: 26%; */
  
}

.headerCharcodeAdd {
  padding: 4px 7px;
  margin-top: 3px;
}

.orderId {
  font-size: 1.2rem;
}

.headerCharcodeCollapsed {
  padding: 0px !important;
  border-radius: 1px solid black;
}

.headerGrass {
  color: var(--leaf);
  margin-right: 4px;
}

.mainIllustration {
  max-width: 70vw;
  max-height: 30vh;
  margin-bottom: -30px;
}

.applicationIllustration {
  max-height: 20vh;
}

.content {
  background-color: #fff;
  min-height: 140px;
  width: 100%;
  padding: 7%;
  margin-top: 10px;
  box-shadow: 6.87509px 9.16679px 18.3336px rgba(0, 0, 0, 0.13), inset 0px 0px 36.6672px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}

.inputRow {
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;
}

.orderSelector {
  /* background-color: pink; */
}

.weightContainer {
  min-width: 100px;
  opacity: 1;
  /* pointer-events: none; */
}

.moistureContainer{
  gap: 15px;
  font-size: 8px !important;
}

.moistureInput {
  font-size: 8px;
}

.signatureContainer {
  position: relative;
  width: 100%;
  height: 160px;
  margin-top: 0px;
  margin-bottom: 15px;
  z-index: 9999999;
}

.resetSignature {
  position: absolute;
  font-size: 17px;
  color: var(--coal);
  top: -25px;
  right: 10px;
  font-family: "FontAwesomeSolid";
  cursor: pointer;
}

.resetSignature:hover{
  opacity: 0.7;
}

.signature {
  border: 3px dotted var(--grey);
  width: 100%;
  height: 100%;
  min-height: 100px;
  border-radius: 30px;
  position: absolute;
}

.patternBg {
  position: absolute;
  width: 140vw;
  height: 140vh;
  background-repeat: repeat;
  object-fit: contain;
  background-image: url(./assets/images/backgroundHexagonTiles.png);
  background-repeat: repeat;
  background-size: 110px;
  filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.16));
  opacity: 0.4;
  transform: rotate(10deg);
}

.line {
  height: 1px;
  width: 40%;
  background-color: var(--greyLight);
  margin-top: -8px;
}


.productInformation {
  font-size: 13px;
  font-family: "Roboto";
  color: var(--greyDark);
}

.productFontAwesome {
  font-family: "FontAwesomeSolid";
  margin-left: 5px;
}



.pattern1 {
  position: absolute;
  max-width: 50px;
  top: 0px;
  left: -2px;
  transform: rotate(90deg);
}

.pattern2 {
  position: absolute;
  max-width: 50px;
  bottom: 0px;
  right: -2px;
  transform: rotate(-90deg);
}

.websiteLink {
  color: var(--grey);
  position: relative;
  bottom: -7px;
  font-family: "TruenoBd";
  letter-spacing: 0.5px;
  font-size: 12px;
}

.websiteLink:active {
  opacity: 0.6;
}

.fontAwesome {
  letter-spacing: 0px;
  font-family: "FontAwesome";
  margin-right: 5px;
}

.ebcLogo {
  max-width:30px;
  margin-right: 20px;
}

.applicationDateContainer {
  width: 50%;
}

.coolFont {
  font-family: "RobotoCondensedMed";
  font-size: 19px;
}

.signatureOverlay {
  background-color: white;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.2s linear;
  display: flex;
  flex-direction: column;
  padding: 30px 28px;
  justify-content: center;
  gap: 8px;
}

.signatureOverlayHidden {
  opacity: 0;
  pointer-events: none;
  display: hidden;
}

.deliveryBackButtonContainer {
  width: 100%;
  background-color: pink;
  display: flex;
  justify-content: flex-start;
}

.deliveryBackButton {
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid black;
}

.backButtonContainer {
  width: 100%;
  margin-bottom: -18px;
  margin-top: 15px;
}

.alreadyAppliedText {
  width: 100%;
  font-size: 11px;
  color: var(--coalVeryLight);
  font-family: "RobotoCondensedMed";
  display: flex;
  justify-content: flex-start;
}

.alreadyAppliedTextBold {
  font-family: "RobotoCondensedMed";
  color: var(--coal);
}

.sectionSignature {
  margin-top: 10px;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

/* .alreadyAppliedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: pink;
  text-align: center;
} */

@media (min-width: 1200px) {
  /* Styles for large desktops */
 /* .fullContainer{
  padding: 100px 100px;
 } */
}

.container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

}

.checkboxRow {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}

.ordersContainer {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.ordersListColumn {
    border: 1px solid black;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.ordersListHeader {
    font-family: "RobotoCondensedBold";
    font-size: 2rem;
}

.orderTable{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-spacing: 0 1.2rem;
}

.orderHeaderRow{
    text-align: left;
  }


.tableRow{
  
    width: 100%;
    height: 3rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    text-align: left;
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
  }

  .ordersPlaceholder {
    width: 100%;
    height: 8rem;
  }

  .buttonRow {
    width: 100%;
    justify-content: flex-end;
  }

  .buttonContainer {
    width: 20rem;
    height: 3rem;
  }

  .scheduleOrderContainer {
    width: 30vw;
    padding: 0 2rem;
    flex-direction: column;
    justify-content: flex-start;
  }


  .cancelOrderContainer {
    width: 30vw;
    padding: 0 4rem 0 4rem;
    flex-direction: column;
    justify-content: flex-start;
  }

  .updateOrderRow {
    margin-bottom: 2rem;
    min-height: 2rem;
    justify-content: space-between;
    width: 100%;
  }

  .datePicker {
    font-family: "RobotoCondensed";
    margin-left: 1rem;
  }
  .calendar {
    display: flex;
  }

  .productCounterContainer {
    height: 15rem;
    width: 100%;
  }

  .editIcon {
    font-family: "FontAwesomeSolid";
    color: var(--coalVeryLight);
    cursor: pointer;
    font-size: 1.1rem;
    pointer-events: auto;
  }

  .editIcon:hover{
    opacity: 0.8;
  }


.statusDelivered {
  color: var(--leaf);
}

.statusUpcoming {
  color: var(--coal);
}

.statusCancelled {
  color: var(--error);
}

.statusIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 0.4rem;
  font-weight: 700;
}

.updateOrderStatus{
  font-size: 1.7rem;
}

.container {
    padding-top: 0rem;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: fit-content;
    font-family: "RobotoCondensedBold";
    font-size: 1.7rem;
}

.contentWrapper {
    width: 100%;
    padding: 2rem 3rem 0 3rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


.header {
    font-size: 4rem;
    margin: 0;
}

.subHeader {
    margin: 0.3rem 0 0 0.1rem;
    font-family: "Roboto";
    font-weight: normal;
    font-size: 1.5rem;
    color: var(--coalVeryLight)
}

.line {
    height: 1px;
    width: 96%;
    margin: 1.2rem 0 1rem 0;
    background: var(--greyLight);
}


.breakdownRow {
    width: 95%;
    gap: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    margin-top: 1%;
}
  
  .separatorLine {
    width: 1px;
    height: 100px;
    background: var(--greyLight);
    margin: 1rem 0;
  }
  
  .breakdownItem {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-family: "RobotoCondensedMed";
    font-size: 1.5rem;
    color: var(--coalLight);
  }
  
  .breakdownValue { 
    
    font-family: "TruenoSBd";
    font-size: 2.5rem;
    color: var(--leaf);
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .unit {
    color: var(--grass);
    font-size: 1.7rem;
    margin-left: 0.5rem;
  }



  .breakdownLabel {
    margin-top: 0;
  }
.calculatorContainer {

    width: 100%;
    flex-direction: column;
}

.inputsContainer {
    margin-top: 2rem;
    height: 100%;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
}

.disabled {
    opacity: 0.6;
}

.inputColumn {
    height: 100%;
    width: 50%;
    gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
}

.inputModule {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 2rem;
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    border: 1px solid #CCCCCC;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 17px;
}


.buttonRow {
    margin-top: 1rem;
    width: 70%;
}
.comingUpContainer {
    width: 100%;
    height: 100%;
    margin-bottom: -3rem;
}

.rowsContainer {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    position: relative;
}

.lineCalendar {
    width: 2px;
    height: calc(100% - 4rem);
    background: var(--grey);
    position: absolute;
    top: 0;
    left: 2.5rem;
}

.eventRow {
    height: 6.7rem;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.calendarIconContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: 5.8rem;
    width: 5.8rem;
}

.calendarIcon {
    position: absolute;
    width: 100%;
}

.calendarMonth {
    font-family: "TruenoBd";
    font-size: 1.1rem;
    color: white;
    margin-top: 0.1rem;
    margin-right: 0.2rem;
    z-index: 3;
    letter-spacing: 0.075rem;
}

.calendarDay {
    font-family: "RobotoCondensedBold";
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--greyDark);
    margin-top: 0.2rem;
    margin-right: 0.2rem;
    z-index: 3;
}

.eventInfoIdContainer {
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 8.1rem);
    height: 100%;
}
.eventInfoContainer {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    margin-left: -0.5rem;
}

.eventHeader {
    font-family: "RobotoCondensedBold";
    color: var(--coalLight);
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
}

.eventIcon {
    font-family: "FontAwesomeSolid";
    margin-right: 0.6rem;
}

.eventIdContainer {
    font-family: "RobotoCondensedBold";
    color: var(--coalVeryLight);
}

.eventContent {
    font-family: "Roboto";
    font-weight: 500;
    color: var(--coalVeryLight);
    line-height: 1.5rem;
}

.eventSeparatorLine {
    height: 1px;
    width: 100%;
    background: var(--greyLight);
    position: absolute;
    bottom: 0;
    right: 0;
}



.mainContainer {
    justify-content: flex-start;
    width: 100%;
    height: 6rem;
    position: relative;
    overflow: visible;
    z-index: 9;
}

.mainContainerNoLabel {
    height: 3.5rem;
}

.mainContainerLabelTop {
    flex-direction: column;
    align-items: flex-start;
}

.selectorContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 2.5rem;
    font-family: "RobotoMed";
    font-size: 1.5rem;
    color: var(--coalVeryLight);
    box-sizing: border-box;
    width: 100%;
    background: white;
    border-radius: 1.5rem;
    box-shadow: 0px 3.45713px 8.64283px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow-y: scroll;
}

.selectorContainerNoLabel {
    top: 0rem;
}

.selectorContainerCollapsed {
    height: 3.2rem;
    overflow-y: hidden;
}


.LabelContainer{
    font-family: "RobotoCondensedMed";
    color: var(--coalLight);
    font-size: 1.4rem;
    padding: 0.3rem;
    padding-top: 0.5rem;
    margin-right: 0.5rem;
}

.LabelIcon{
    font-family: "FontAwesomeSolid";
    margin-right: 0.7rem;
}

.arrow {
    position: absolute;
    max-width: 1.3rem;
    right: 1.6rem;
    top: 0.8rem;
    z-index: 1;
    transition: transform 0.2s ease;
}

.arrowReversed {
    transform: scaleY(-1);
    top: 1.2rem;
}

.selectedOption {
    position: absolute;
    justify-content: flex-start;
    padding: 1.6rem;
    background: white;
    height: 3.2rem;
    width: 100%;
    top: 0rem;
    user-select: none;
}

.selectedOptionPlaceholder {
    color: var(--greyLight) !important ;
}

.selectedOptionLineFocused {
    position: absolute;
    top: 3.1rem;
    height: 1px;
    width: 92%;
    background: var(--greyLight);
}

.optionsContainer { 
    flex-direction: column;
    justify-content: flex-start;
    max-height: 300px;
    width: 100%;
    padding-top: 3rem;
    overflow-y: scroll;
    opacity: 1;
    transition: max-height 0.2s linear;
}

.optionsContainerCollapsed {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
}

.optionRow {
    width: 100%;
    padding: 1rem;
    padding-left: 1.6rem;
    user-select: none;
    border-radius: 1.5rem;
    justify-content: flex-start;
    transition: background 0.2s;
}

.optionRow:hover {
    background: var(--greyLight);
    color: var(--coal);
}

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4rem;


}

.invoicesContainer {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  flex-direction: column;
  align-items: flex-start;

}

.invoicesListColumn {
  border: 1px solid black;
  width: 50%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.invoicesListHeader {
  font-family: "RobotoCondensedBold";
  font-size: 2rem;
}

.invoiceTable{
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-spacing: 0 1.2rem;
}

.invoiceHeaderRow{
  text-align: left;
}


.tableRow{

  width: 100%;
  height: 3rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: left;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
}

.invoicesPlaceholder {
  width: 100%;
  height: 8rem;
}

.buttonRow {
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 12rem;
}

.buttonContainer {
  width: 20rem;
  height: 3rem;
}

.scheduleInvoiceContainer {
  width: 30vw;
  padding: 0 4rem 0 4rem;
  flex-direction: column;
  justify-content: flex-start;
}


.cancelInvoiceContainer {
  width: 30vw;
  padding: 0 4rem 0 4rem;
  flex-direction: column;
  justify-content: flex-start;
}

.updateInvoiceRow {
  margin-bottom: 2rem;
  min-height: 2rem;
  justify-content: space-between;
  width: 100%;
}

.datePicker {
  font-family: "RobotoCondensed";
  margin-left: 1rem;
}
.calendar {
  display: flex;
}

.productCounterContainer {
  height: 15rem;
  width: 100%;
}

.tableRowLinks {
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.editIcon {
  font-family: "FontAwesomeSolid";
  color: var(--coalVeryLight);
  cursor: pointer;
  font-size: 1.1rem;
  pointer-events: auto;
  text-decoration: none;
}

.editIcon:hover{
  opacity: 0.8;
}


.statusPaid {
color: var(--leaf);
}

.statusUpcoming {
color: var(--coal);
}
.statusLate {
  color: #ffb642d9;
}

.statusCancelled {
color: var(--error);
}

.statusIcon {
font-family: "FontAwesomeSolid";
margin-right: 0.4rem;
font-weight: 700;
}

.updateInvoiceStatus{
font-size: 1.7rem;
}

.newInvoiceRow {
  width: 100%;
  margin-bottom: 2rem;
}

.linkDisabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.linkDisabled:hover {
  opacity: 0.3;
}

.container {
    flex-direction: column;
    max-width: 380px;
}

.subHeader {
    font-family: "Roboto";
    font-size: 1.4rem;
    color: var(--coalLight);
    text-align: center;
    margin-bottom: 1rem;
}

.inputWrapper {
    margin-bottom: 0.5rem;
    width: 100%;
}

.buttonWrapper {
    margin-top: 0.5rem;
    width: 100%;
}

.prompt {
    height: 3rem;
    text-align: center;
}

.promptConfirmation {
    color: var(--coalLight) !important;
}
.container {
    width: 100%;
    background-color: pink;
}



.siteContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
}

.headerRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 17px;
    margin-bottom: 8px;
}

.headerIcon {
    max-width: 13%;
    opacity: 0.7;
    margin-right: 4%;
}

.headerDatesContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
}

.headerDateContainer {
    padding: 2px 2px;
    display: flex;
    flex-direction: column;
    width: 8%;
}

.weekIndex {
    font-family: "RobotoCondensed";
    color: var(--grey);
    font-size: 11px;
    margin-bottom: 3px;
}

.date {
    font-family: "Trueno";
    color: var(--coalLight);
    font-size: 10px;
}

.weekIndexSelected {
    font-weight: 600;
    color: var(--coal);
}

.row {
    width: 100%;
}

.rowDark {
    background-color: var(--greyVeryLight);
}

.rowContentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    margin-left: 30px;
    padding: 9px 0px;
    border-radius: 20px;
}


.rowHeader {
    width: 17%;
    display: flex;
    justify-content: flex-start;
}

.siteDivider {
    width: 100%;
    height: 1px;
    background-color: var(--greyLight);
    margin-top: 19px;
}

.rowValue {
    width: 8%;
    text-align: center;
    background-color: transparent;
    border: none;
    font-family: "RobotoCondensed";
    color: var(--coalVeryLight);
}

.rowValueCurrentWeek {
    font-weight: 1000;
    color: var(--coalDark);
}

.editIcon {
    font-family: "FontAwesomeSolid";
    position: absolute;
    top: -30px;
    right: 0;
    cursor: pointer;
    font-size: 17px;
}

.editIcon:hover {
    opacity: 0.6;
}

.contentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}


.closeIcon {
    color: var(--error);
}

.confirmIcon {
  right: 30px;
  color: var(--leaf);
}

.contentGrid {
    gap: 0;
    padding-bottom: 10px;
}


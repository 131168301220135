.filtersContainer{
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

.header {
  margin: 0;
  padding: 0;
  font-family: "Trueno";
  font-size: 2.1rem;
  font-weight: normal;
  color: var(--coalLight);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0rem;
}

.filtersSection {
  width: 100%;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  border: 0.5px solid #DADADA;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
  border-radius: 0.7rem;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Roboto";
  font-size: 1.4rem;
  color: var(--coalVeryLight);
  transition: 0.3s;
  max-height: 500px;
  overflow: hidden;
}


.filtersSectionCollapsed {
  max-height: 27px;
}


.sectionHeader {
  background: var(--greyLight);
  width: 100%;
  border-radius: 0.6rem 0.6rem 0rem 0rem;
  padding: 5px 20px;
  font-family: "RobotoCondensed";
  font-weight: bold;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  color: var(--coalLight);
}

.sectionHeader:hover {
  opacity: 0.7;
}

.expandIcon {
  font-family: "FontAwesome";
}

.checkboxesContainer{
  display: flex;
  width: 100%; 
  padding: 1.2rem 3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}


.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.reviewDescription {
  width: 100%;
  justify-content: flex-start;
}

.contentContainer {
  margin-top: 2rem;
  width: 100%;
  gap: 1.2rem;
}

.column {
  width: 50%;
  height: 100%;
  flex-direction: column;
  gap: 1.2rem;
}

.reviewModule {
  width: 100%;
  height: 100%;
  padding: 1.1rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  border: 1px solid #CCCCCC;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.11);
  border-radius: 17px;
}

.moduleHeader {
  font-family: "RobotoCondensed";
  font-weight: 100;
  font-size: 2rem;
  letter-spacing: -0.02rem;
  margin-bottom: 0.4rem;
  color: var(--coalLight);
}

.overviewRow {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.contentSection {
  display: block;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid var(--greyLight);
  margin: 1rem 0 1rem -0rem;
  padding-left: 0.8rem;
  font-family: "Roboto";
  color: var(--coalLight);
  font-weight: 700;
}

.contentDelivery1 {
  width: 50%;
  font-weight: 500;

}

.contentDelivery2 {
  width: 100%;
  font-weight: 500;

}

.contentHeader {
  font-family: "RobotoCondensed";
  font-weight: 700;
  justify-content: flex-start;
  font-size: 1.5rem;
  letter-spacing: -0.02rem;
  margin-bottom: 1rem;
  color: var(--coalLight);
}

.contentRow {
  justify-content: flex-start;
  margin-bottom: 0.6rem;
}

.contentBold {
  letter-spacing: -0.02rem;
  margin-left: 0.2rem;
  font-weight: 700;
  font-family: "RobotoCondensed";
}

.contentBoldDeliveries {
  margin-left: 0.7rem;

}

.icon {
  font-family: "FontAwesomeLight";
  font-size: 1.3rem;
  justify-content: flex-start;
  width: 2rem;
  margin-right: 0.3rem;
}

.exp{
  font-size: 0.8rem;
  position: relative;
  bottom: 0.4rem;

}

.buttonRow {
  margin-top: 1.6rem;
  width: 100%;
  gap: 2rem;
}

.overlayContainer {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.51);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: opacity 0.3s linear;
}

.overlayContainerDisplay {
  opacity: 1;
  pointer-events: all;
}
.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.headerRow {
  width: 100%;
  margin-top: 0.7rem;
  height: 3.4rem;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.6rem;
  font-family: "Trueno";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
}

.headerFilter {
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  cursor: pointer;
  user-select: none;
  font-family: "RobotoCondensed";
}

.headerFilter:hover {
  opacity: 0.6;
}

.headerFilterSelected {
  height: calc(100% - 2px);
  color: var(--coalLight);
  border-bottom: 2px solid var(--coalLight);
}

.farmersTable {
  width: 100%;
  margin-top: 0.2rem;
  border-spacing: 0 1rem;
}

.farmersTableWrapper {
  /* border: 1px solid black; */
  width: 100%;
  max-height: 360px;
  overflow-y: scroll;
  justify-content: flex-start;
  align-items: flex-start;
}


.farmersHeaderRow{
  text-align: left;
}

th:first-child{
  padding-left: 1rem;
}

td:first-child{
  padding-left: 1.4rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -webkit-border-radius: 1rem 0 0 1rem;
}
td:last-child{
  -moz-border-radius: 0 1rem 1rem 0;
  -webkit-border-radius: 0 1rem 1rem 0;
}



.tableRow{
  width: 100%;
  height: 3.5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: left;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  cursor: pointer;
}

.tableRow:hover {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.17);
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #ffffff 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
}

.tableRowSelected {
  background: var(--coal);
  color: white;
}

.tableRowSelected:hover {
  background: var(--coal);
}

.dataBold {
  font-weight: 700;
}

.exp {
  font-size: 0.8rem;
  position: relative;
  bottom: 0.5rem;
}


.statusDelivered {
  color: var(--leaf);
  font-weight: 700;
}

.statusUpcoming {
  color: var(--coal);
  font-weight: 700;
}

.statusResolved {
  color: var(--grassDark);
  font-weight: 700;
}

.statusIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 0.4rem;
}

.farmerHeaderContainer {
  flex-direction: column;
  width: 100%;
}

.farmerHeaderName {
  font-family: "TruenoBd";
  color: var(--coal);
  font-size: 3rem;
  width: 100%;
  justify-content: flex-start;
}

.farmerHeaderBusiness {
  font-family: "Roboto";
  color: var(--coalVeryLight);
  font-size: 1.4rem;
  width: 100%;
  justify-content: flex-start;
}

.farmerHeaderLine {
  height: 1px;
  width: 104%;
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  background: var(--greyLight);
}

.farmerContentContainer {
  width: 100%;
  min-height: 450px;
}

.farmerContentMenuTabs {
  height: 100%;
  width: 15%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.3rem;
}

.menuTab {
  font-size: 1.5rem;
  width: 100%;
  margin-right: 2rem;
  height: 3rem;
  padding: 0 0 0 2rem;
  justify-content: flex-start;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

.menuTab:hover{
  opacity: 0.8;
}

.menuTabSelected {
  background: var(--greyVeryLight);
  font-weight: bold;
  color: var(--coalLight);
}

.farmerContentPage {
  height: 100%;
  width: 85%;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  border: 0.5px solid #DADADA;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
  border-radius: 1.3rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 3rem 1.5rem 3rem;
  font-family: "Roboto";
  font-size: 1.4rem;
  color: var(--coalVeryLight);
}

.header{
  margin: 0;
  padding: 0;
  font-family: "Trueno";
  font-size: 2.1rem;
  font-weight: normal;
  color: var(--coalLight);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.line {
  margin: 0.3rem 0rem 0.6rem 0rem;
  width: calc(100% + 1rem);
  height: 1px;
  background: var(--greyVeryLight);
}

.inviteFarmerButton {
  position: absolute;
  width: 16rem;
  top: 4rem;
  right: 4rem;
}

.ticketContainer {
  width: 60rem;
  flex-direction: column;
  padding: 0rem 2rem 0 2rem;
}

.ticketOverlayRow {
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.ticketOverlayLabel {
  font-family: "RobotoCondensedMed";
  margin-right: 2rem;
}
.mainContainer {
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1vw;
    opacity: 1;
    animation: fade 0.4s linear;
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

.row {
    height: 3rem;
}

.bigCircle {
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 50%;
    border: 0.25rem solid;
    border-color: #939393;
    transition: all 0.5s;
}

.bigCircleActive {
    border-color: #fff;
    box-shadow: 0px 0px 11px rgba(255, 255, 255, 0.51);
}

.bigCircleComplete {
    border-color: var(--leaf);
}



.smallCircle {
    height: 0rem;
    width: 0rem;
    border-radius: 50%;
    background: white;
    transition: all 0.5s;
}

.smallCircleActive {
    height: 1.2rem;
    width: 1.2rem;
}

.smallCircleComplete {
    height: 1.2rem;
    width: 1.2rem;
    background: var(--grass);
}

.progressStepName {
    font-size: 1.6rem;
    margin-left: 1.3rem;
    font-family: "RobotoCondensed";
    font-weight: 600;
    color: #939393;
    transition: all 0.5s;

}

.progressStepNameActive {
    color: #fff;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.65);
}

.progressStepNameComplete {
    color: var(--grass);
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.25);
}

.dots {
    height: 1.2rem;
    width: 0.3rem;
    margin: 0.5rem;
    margin-left: 1.04rem;
    background: #939393;
    transition: all 0.5s;

}


.dotsComplete {
    background: var(--grass);
}

.gaugeBg{
    margin-top: 1.5rem;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }

  .gaugeMiddle{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: #fff;
    flex-direction: column;
    font-family: "RobotoCondensed";
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--leaf);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

  }
  
  .gaugeTextBags {
    color: var(--grass);
  }
.termsAndConditionsContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 3rem 2rem 3rem;
    width: 42rem;
    height: 50rem;
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.11), inset 0px 0px 19px rgba(0, 0, 0, 0.68);
    border-radius: 13px;
    position: relative;
  }

  .header {
    font-family: "TruenoSBd";
    font-weight: 600;
    font-size: 2.2rem;
    color: var(--grass);
    letter-spacing: 0.035em;

  }

  .subHeader {
    font-family: "RobotoCondensed";
    color: var(--coalLight);
    font-size: 1.4em;
    margin-bottom: 1rem;
  }

  .termsContent {
    font-family: "Roboto";
    display: block;
    color: var(--coalVeryLight);
    width: 100%;
    padding: 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    overflow-y: scroll;
  }

  .buttonRow {
    width: 100%;
    margin-top: 1rem;
    gap: 1rem;
  }

  .bbbLogo {
    position: absolute;
    max-width: 12%;
    top: 2rem;
    right: 2rem;
  }

  .termsAndConditionsRTE {
    display: block;
    overflow-y: scroll;
  }

  .divBlock217 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .divBlock79 {
    font-size: 18px;
    font-weight: bold;
    color: var(--coalLight);
  }

  .divBlock218 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  li{
    font-size: 13px;
    color: var(--coalLight);
  }

  h3 {
    font-size: 16px;
    color: var(--coalLight);
  }
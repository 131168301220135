input[type='range'] {
    -webkit-appearance: none !important;
    background: var(--grass);
    height:0.4rem;
    width: 100%;
    -webkit-fill-range-track: #e6e6e6;
    -webkit-fill-range-track-active: #60cd18;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background:white;
    border: 0.3rem solid var(--leaf);
    height:1.9rem;
    width:1.9rem;
    border-radius: 50%;
    transition: height 0.2s linear, width 0.2s linear;

} 

input[type='range']:hover {
    cursor: pointer;
}


input[type='range']::-webkit-slider-thumb:hover {

    height:2.2rem;
    width:2.2rem;
    border-radius: 50%;
} 

.sliderContainer {
    width: 100%;
    flex-direction: column;
}

.topLabelRow {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1.1rem;
}

.sliderName {
    font-family: "RobotoCondensed";
    font-weight: 700;
    color: var(--coalLight);
}

.areaExp {
    position: relative;
    bottom: 0.5rem;
    margin-right: 0.2rem;
    font-size: 0.9rem;
}

.sliderInput {
    width: 5rem;
    height: 2.2rem;
    margin-bottom: 0.4rem;
    border-radius: 2rem;
    border: 0.1rem solid var(--grass);
    font-family: "RobotoCondensed";
    color: var(--coalLight);
    font-weight: 700;
    text-align: center;
    outline: none;
    box-shadow: 0px 3.12273px 7.80682px rgba(0, 0, 0, 0.1);
}

.sliderInput:focus {
    border: 0.2rem solid var(--grass);
    width: 4.8rem;
    height: 2rem;
}

.rangesRow {
    width: 100%;
    margin-top: 0.7rem;
    justify-content: space-between;
}

.rangeLabel {
    font-family: "RobotoCondensed";
    font-weight: 700;
    color: var(--leaf);
}
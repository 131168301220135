.filtersRow {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.filterContainer {
    position: relative;
}

.searchContainer {
    width: 18%;
}

.statusContainer {
    width: 13%;
}

.siteContainer {
    width: 13%;
}

.dateContainer {
    width: 13%;
}

.fromContainer {
    width: 15%;
}

.toContainer {
    width: 15%;
}

.bagsTable {
    width: 100%;
    padding: 0;
    padding-bottom: 50px;

    margin-top: 14px;
    border-collapse: separate;
    border-spacing: 0 10px; /* 0 for columns, 10px for rows */
}

.bagsHeaderRow {
    

}

.bagsRow {
    width: 100%;
    height: 45px;
    padding: 10px 0;
    outline: 1px solid var(--greyLight);
    border-radius: 7px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.14);
    cursor: pointer;
}

.bagsRow:hover {
    opacity: 0.7;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
}


.bagsHeader {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-family: "RobotoCondensed";
    color: var(--coalLight);
    text-align: left;
    cursor: pointer;
    user-select: none;
}

.bagsHeader:hover {
    opacity: 0.7;
}

.bagsValue {
    padding: 0;
    text-align: left;
    margin-bottom: 3px;

}

.tableCharcode {
    font-family: "Trueno";
    font-weight: 600;
}

.greyedOut {
    opacity: 1;
}

.editRowIcon {
    font-family: "FontAwesomeSolid";
    text-align: center;
    cursor: pointer;
} 

.editRowIcon:hover {
    opacity: 0.7;
}



.placeholderShape {
    background: var(--greyVeryLight);
    width: 140px;
    height: 20px; 
    border-radius: 10px;
}

.fontAwesome {
    font-family: "FontAwesomeSolid";
    position: absolute;
    right: 12px;
    bottom: 10px;
    color: var(--greyDark);
}

.filtersReset {
    font-family: "FontAwesomeSolid";
    display: flex;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -25px;
    cursor: pointer;
}

.filtersReset:hover {
    opacity: 0.6;
}

.iconCSV {
    max-height: 30px;
    cursor: pointer;
    user-select: none;
    margin-right: 7px;
}

.iconCSV:hover {
    opacity: 0.8;
}

.exportToCSVContainer {
    position: absolute;
    top: 70px;
    right: 10px;
    z-index: 999;
}
.overlayBg {
    position: fixed;
    top: 0;
    left: 10px;
    min-height: 100vh;
    width: 100vw;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    overflow-y: scroll;
    overflow-x: hidden;
    opacity: 0;
    animation: fadeInAnimation 0.2s ease-in-out forwards; /* Adjust the duration and timing function as needed */
}

@keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.overlayClosePopup {
    cursor: pointer;
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.overlay {
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    border: 0.5px solid #DADADA;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
    border-radius: 1.3rem;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Roboto";
    font-size: 1.4rem;
    color: var(--coalVeryLight);
    min-width: 400px;
    z-index: 10;
    position: absolute;
}

.headerBar {
    background: var(--coal);
    width: 100%;
    padding: 0.6rem 3rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    outline: 1px solid var(--coal);
    border-radius: 1rem 1rem 0 0;
    font-size: 1.6rem;
    font-family: "Trueno";
}

.headerIcon {
    font-family: "FontAwesomeSolid";
    margin-right: 1rem;
    font-size: 1.4rem;
}

.overlayContent {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
}

.overlayRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 5px;
}

.selectHighZIndex {
    z-index: 99999999;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.inputContainer {
    width: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputFullRowContainer {
    width: 320px;
    display: flex;
    flex-direction: column;
}

.checkboxContainer {
    padding-top: 15px;
    padding-left: 9px;
}

.bagsLabelContainer{
    font-family: "RobotoCondensedMed";
    color: var(--coalLight);
    font-size: 1.4rem;
    padding: 0.3rem;
    padding-left: 1.3rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.bagsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    width: 100%;
    margin-bottom: 1rem;
    max-height: 500px;
    transition: 0.3s;
}

.bagsContainerCollapsed {
    max-height: 0;
    opacity: 0;
    user-select: none;
    pointer-events: none;
}


.collapseIcon {
    max-height: 14px;
    user-select: none;
    transform: rotateX(180deg);
}

.collapseIconFlipped {
    transform: rotateX(0deg)
}

.headerOrderId {
    font-family: "RobotoCondensedBold";
}


.App {
  text-align: center;
}

.BgContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
  /* background-color: pink; */
  overflow: hidden;
  background: linear-gradient(336deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgb(31, 31, 31) 100%);
}

.BgHexOverlay{
  position: absolute;
  z-index: 0;
  top: -10vh;
  left: 0px;
  width: 120%;
  height: 120%;
  opacity: 0.3;
  background-image: url("./assets/images/backgroundHexagonTiles.png");
  background-repeat: repeat;
  background-size: 15%;
  filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.567));
  transform: rotate(3.06deg);

 /* animation: gradient 25s ease infinite;*/
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 10% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


.MainContainer {
  z-index: 1;
  /* overflow: hidden; */

}

@media only screen and (max-width: 650px) { 
  .MainContainer {
    overflow: hidden;/*???*/
  }

  .BgHexOverlay{
    z-index: 0;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    transform: rotate(0deg);
   /* animation: gradient 25s ease infinite;*/
  }

 

  .BgContainer {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;

  }
}
.productCounterContainer {
    width: calc(100% + 2rem);
    height: 100%;
    background: white;
    flex-direction: column;
    gap: 2rem;
    font-family: 'RobotoCondensed';
}

.productCounterContainerDisabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.headerRow {
    height: 2.5rem;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
}

.contentRow {
    width: 100%;
    height: 5.5rem;
    justify-content: space-between;
}

.productIcon {
    max-height: 2.5rem;
    margin:0 0.5rem 0 -0.5rem;
}

.productNameContainer {
    font-weight: 700;
    color: var(--coalLight);
    font-size: 1.9rem;
}

.priceContainer {
    letter-spacing: -0.03rem;
    color: var(--greyDark);
    margin-bottom: 0.4rem;
}

.volumeWeightContainer {
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.5rem;
}

.volumeWeightRow {
    width: 5rem;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 1.2rem;
    color: var(--coalVeryLight);
    justify-content: flex-start;
}

.unitIcon {
    font-family: "FontAwesomeLight";
    margin-right: 0.6rem;
}

.unitValue {
    color: var(--coalLight);
    margin-right: 0.2rem;
    letter-spacing: -0.05rem;
}

.unitExp {
    font-size: 0.8rem;
    margin-top: -1rem;
}

.separatorLine {
    margin-top: -1rem;
    width: 100%;
    height: 1px;
    background: var(--greyLight);
}
.container {
    width: 3.8rem;
    height: 1.9rem;
    border-radius: 1.9rem;
    background: #fff;
    outline: 0.2rem solid var(--grey);
    position: relative;
    cursor: pointer;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.75;
    transition: opacity 0.3s linear;
}

.containerToggled {
    opacity: 1;
}

.circle {
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 50%;
    background: var(--coalLight);
    position: absolute;
    left: 0;
    box-shadow: inset 0px 0px 9.02778px #000000;
    transition: left 0.3s ease;
}

.circleToggled {
    left: 1.9rem;
}
.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.deleteAccountContainer {
  height: 100%;
  padding: 1.5rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
}

.deleteText {
  font-family: "Roboto";
  font-size: 1.5rem;
}

.deleteButtonRow {
  width: 28rem;
}

.contentGrid {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start; */
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
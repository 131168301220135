.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}


.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.detailsContainer {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.detailsButtonsRow {
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  gap: 1rem;
}

.gaugesContainer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gaugeLabel {
  font-family: "TruenoSBd";
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: 1rem;
  letter-spacing: -0.05rem;
}

.gaugesSeparatorLine {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  width: 90%;
  background: var(--greyLight);
}

.detailsWrapper{
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.detailsRow{
  margin-top: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.5rem;
  color: var(--coalVeryLight);
}

.detailsRowSection {
  width: 50%;
  padding-left: 0.6rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 1px solid var(--greyLight);
  font-family: "RobotoCondensed";
}

.detailsRowSectionFullWidth {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  font-family: "Roboto";

}

.detailsRowSectionHeader {
  font-weight: 600;
  margin-bottom: 0.7rem;
  color: var(--coalLight);
  font-family: "RobotoCondensed";
}

.storageDeliveryContent {
  margin-top: 10%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.storageDeliveryRow {
  width: calc(100% + 3rem);
  height: 6rem;
  color: white;
  justify-content: horizontal;
}

.storageDeliverySeparatorLine {
  width: 100%;
  height: 1px;
  background: var(--greyLight);
  margin: 1rem 0;
}

.storageDeliveryIcon {
  font-family: "FontAwesomeSolid";
  font-size: 2.4rem;
  margin-right: 1rem;
}

.weightText {
  display: block;
  font-family: "TruenoRg";
  color: var(--greyLight);
}

.storageDeliveryRow {
  justify-content: flex-start;
  padding-left: 1.8rem;
}

.storageDeliveryValue {
  font-weight: 700;
}

.weightAmountExp {
  position: relative;
  bottom: 0.5rem;
  font-size: 1rem;
}

.invoiceContainer {
  width: calc(100% + 4rem);
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

}

.invoiceTable {
  margin-top: 1.5rem;
  flex-direction: column;
  width: 100%;
}

.invoiceRow {
  width: 100%;
  padding: 2rem;
  height: 3.5rem;
  border-radius: 2rem;
  box-shadow: 0px 0px 8px #FFFFFF;
  background: var(--greyVeryLight);
  justify-content: space-between;
  font-family: "RobotoCondensed";
  font-weight: 700;

}

.invoiceRowWhite {
  background: transparent;
}

.invoiceRowBlack {
  background: var(--coalLight);
}

.invoicePriceBlack {
  color: #fff;
}

.invoiceItemBlack {
  color: #fff;
}


.invoiceItem {
  color: var(--coalVeryLight);
}

.invoicePrice {
  font-weight: 700;
  color: var(--coalLight);
}

.invoiceLine {
  height: 1px;
  width: 92%;
  background: var(--greyLight);
  margin: 2rem 0 2rem 0;
}

.hexagon {
  width: 2.8rem;
  height: 2.5rem;
  margin-left: 0.2rem;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "RobotoCondensedBold";
  color: #fff;
  font-size: 1.6rem;
  background: var(--coalLight);
  clip-path: polygon(
    25% 0%, 75% 0%, 
    100% 50%, 
    75% 100%, 25% 100%, 
    0% 50%
  );
}

.headerContainer {
  margin-left: -4rem;
  font-family: "Trueno";
  display:flex;
  gap: 12px;
  font-size: 1.6rem;
  color: var(--coalLight);

  justify-content: flex-start;
    width: 100%;
}

.hexagonLeaf {
  background: #fff;
  color: var(--leaf);
}

.headerTextLeaf {
  color: white;
}

.voucherContentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.productContainerSelected {

}

.productsContainer {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.productContainer {
  width: 47%;
  padding: 1.2rem 2rem;
  border: 1px solid var(--greyLight);
  border-radius: 1rem;
  opacity: 0.5;
  pointer-events: none;
}

.productContainerSelected {
  opacity: 1;
  pointer-events: all;

}

.separatorLine {
  width: 1px;
  height: 69%;
  margin: 1rem 0;
  border: 1px dashed var(--greyLight);
}

.planDescription {
  margin: 0.5rem 0 2rem 0;
}
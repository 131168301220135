.overlayBg {
    position: fixed;
    top: 0;
    left: 10px;
    min-height: 100vh;
    width: 100vw;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    z-index: 12;
    overflow-y: scroll;
    overflow-x: hidden;
    opacity: 0;
    animation: fadeInAnimation 0.2s ease-in-out forwards; /* Adjust the duration and timing function as needed */
}

@keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.overlayClosePopup {
    cursor: pointer;
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.overlay {
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    border: 0.5px solid #DADADA;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
    border-radius: 1.3rem;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Roboto";
    font-size: 1.4rem;
    color: var(--coalVeryLight);
    min-width: 800px;
    z-index: 10;
    position: absolute;
}

.headerBar {
    background: var(--coal);
    min-width: 800px;
    padding: 0.6rem 3rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    outline: 1px solid var(--coal);
    border-radius: 1rem 1rem 0 0;
    font-size: 1.6rem;
    font-family: "Trueno";
}

.headerIcon {
    font-family: "FontAwesomeSolid";
    margin-right: 1rem;
    font-size: 1.4rem;
    padding: 3px 6px;
    cursor: pointer;
}

.headerIcon:hover {
    opacity: 0.6;
}

.overlayContent {
    width: 800px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 4rem;
}

.firstRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.inventoryContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.inventoryHeaderRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;
    padding: 0 7px;
}

.inventoryHeader {
    font-family: "RobotoCondensedMed";
    font-size: 18px;
    color: var(--coalLight);
    width: 50%;
    display: flex;
    justify-content: flex-start;
    padding-left: 7px;
}

.inventorySiteHeader {
    width: 20%;
    width: 16%;
    font-family: "Trueno";
    font-size: 12px;
    justify-content: center;
}

.inventoryRow {
    padding: 7px;
    border-radius: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--greyVeryLight);
    font-family: "Trueno";
    font-size: 13px;
}

.inventoryRowTransparent {
    background-color: white;
}

.inventoryField {
    padding-left: 7px;
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.notesContainer {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.notesHeaderRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.notesHeader {
    font-family: "RobotoCondensedMed";
    font-size: 18px;
    color: var(--coalLight);
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.notesIcon {
    font-family: "FontAwesomeSolid";
    cursor: pointer;
    user-select: none;
}

.notesIcon:hover {
    opacity: 0.7;
}

.confirmIcon {
    margin-right: 10px;
    color: var(--leaf);
  }

.closeIcon {
    color: var(--error);
}

.notesText {
    min-height: 140px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    font-family: "Roboto";
    line-height: 17px;
    color: var(--coalVeryLight);
    border-radius: 10px;
    padding: 3px;
}

.notesText:focus {
    outline: none;
}

.notesTextEditing {
    outline: 1px solid var(--greyLight);
}

.notesTextEditing:focus {
    outline: 1px solid var(--greyLight);
}

.eventsContainer {
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.eventsHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: "RobotoCondensedMed";
    font-size: 18px;
    color: var(--coalLight);
    padding-left: 7px;
}

.addEvent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--grey);
    border-radius: 15px;
    padding: 0.5rem 1.2rem;
    height: 3rem;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.7rem;
    margin-top: 1.5rem;
    user-select: none;
}

.eventRowsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
}

.eventContainer {
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    border: 0.5px solid #DADADA;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
    border-radius: 1.3rem;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 3rem 1.5rem 3rem;
    font-family: "Roboto";
    font-size: 1.4rem;
    color: var(--coalVeryLight);
    width: 100%;
    cursor: pointer;
}

.eventContainer:hover {
    opacity: 0.7;
}

.eventHeaderRow {
    width: 100%;
    border-bottom: 1px solid var(--greyLight);
    padding: 3px 3px 7px 3px;
    display: flex;
    justify-content: space-between;
}

.eventHeaderRowSection {
    /* width: 35%; */
}

.eventHeaderField {
  font-family: "RobotoMed";
}

.eventContent {
    width: 100%;

}

.iconFontAwesome {
    font-family: "FontAwesomeSolid";
    margin-right: 5px;
    min-width: 18px;
}

.orderTag {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.18)), #323232;
    box-shadow: 1px 2px 2.2px rgba(0, 0, 0, 0.25), inset 0px 0px 4px rgba(0, 0, 0, 0.24);
    border-radius: 33px;
    color: #fff;
    padding: 1px 7px;
    font-family: "RobotoCondensedBold";
}

.eventHeader{
    font-family: "RobotoCondensedMed";
    color: var(--coalLight);
    margin-left: 15px;
    font-size: 16px;
}

.eventColumn {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.commentHeader {
    font-family: "RobotoCondensedMed";
    color: var(--coalLight);
    font-size: 15px;
    margin: 4px 0;
}

.commentContent {
    font-family: "Roboto";
    color: var(--coalVeryLight);
    font-size: 13px;
}

.eventRow {
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 7px;
}

.eventStat {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: "RobotoMed";
}

.logoContainer {
    height: 16%;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -5vh;
    z-index: 3;
}
  
.bbbLogo {
    margin-top: 1vh;
    height: 100%;
}

.questionaireHeader {
    margin-bottom: 3rem;
}

.questionaireContent {
    width: 70%;
    height: 100%;
    margin-top: 2rem;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.9s;
}

.questionaireContentHidden {
    opacity: 0;
}

.questionRow {
    width: 100%;
    margin-top: 2rem;
}

.checkboxRow {
    width: 100%;
    margin-top: 3rem;

    flex-direction: row;
    justify-content: flex-start;
    gap: 3rem;
}

.buttonRow {
    margin-top: 2rem;
    width: 100%;
    gap: 2.2rem;

}
.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}
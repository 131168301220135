.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.contentGrid {
  width: 100%;
  /* margin-left: 70px; */
  position: relative;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  margin-top: -0.4rem;
}

.mapContainer {
  width: 68%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid blue; */
}

.headerRow {
  width: 100%;
  margin-top: -0.7rem;
  height: 3.4rem;   
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.6rem;
  font-family: "TruenoRg";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
}

.headerFilter {
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

.headerFilter:hover {
  opacity: 0.6;
}

.headerFilterSelected {
  height: calc(100% - 2px);
  color: var(--coalLight);
  border-bottom: 2px solid var(--coalLight);
}

.orderTable {
  width: 100%;
  margin-top: 1rem;
  border-spacing: 0 1.2rem;
}

.orderHeaderRow{
  text-align: left;
}

.zoneRow {
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  width: 100%;
  padding: 15px 15px;
  border: 1px solid var(--greyLight);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--coalLight);

}

.zoneRow:hover {
  opacity: 0.8;
  transform: scale(1.02);
}

.zoneRowSelected {
  background: var(--coal);
  color: white !important;
}

.zoneName {
  font-family: "TruenoSBd";
  font-size: 1.2rem;
}

.placeholderZoneName {
  background-color: var(--greyVeryLight);
  width: 4rem;
  height: 1.5rem;
  border-radius: 7px;
}



th:first-child{
  padding-left: 1rem;
}

td:first-child{
  padding-left: 1.4rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -webkit-border-radius: 1rem 0 0 1rem;
}
td:last-child{
  -moz-border-radius: 0 1rem 1rem 0;
  -webkit-border-radius: 0 1rem 1rem 0;
}



.tableRow{
  
  width: 100%;
  height: 5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: left;
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
}

.dataBold {
  font-weight: 700;
}

.exp {
  font-size: 0.8rem;
  position: relative;
  bottom: 0.5rem;
}


.statusDelivered {
  color: var(--leaf);
  font-weight: 700;
}

.statusUpcoming {
  color: var(--coal);
  font-weight: 700;
}

.statusCancelled {
  color: var(--error);
  font-weight: 700;
}

.statusIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 0.4rem;
}


.newZoneButtonContainer {
  width: 140px;
  position: absolute;
  top: -80px;
  right: 30px;
}

.zonesListContainer {
  /* background-color: pink; */
  height: 100%;
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  padding-left: 7px;
}

.zonesListHeaderRow {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: "RobotoCondensedMed";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
  padding: 0 0.4rem;
}

.selectedZoneMarker {
  opacity: 0.2;
}

.zoneMarker {
  opacity: 0.2;
}

.polygonLabel {
  background-color: var(--coal);
  padding: 0.2rem 0.8rem;
  border-radius: 8px;
  cursor: pointer;
}

.polygonLabelSelected {
  background-color: var(--leaf);
}

.mapTabsWrapper {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 0.5rem; */
}

.plansContainer {
  color: var(--grey);
  
  display: flex;
  justify-content: flex-start;
}


.navContainer {
  width: 100%;
  color: var(--grey);
  border-bottom: 1px solid var(--greyLight);
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.planOption {
  padding: 0.3rem 1rem;
  min-width: 9rem;
  font-family: "RobotoCondensedMed";
  font-size: 1.7rem;
  cursor: pointer;
  border-bottom: 0px solid var(--grey);
  transition: all 0.3s;
  margin-bottom: 3px;
  user-select: none;
}

.planOptionSelected {
  color: var(--coalLight);
  cursor: default;
  margin-bottom: 0px;
  border-bottom: 3px solid var(--grey);
}

.planOptionNew {
  padding: 0.3rem 1rem;
  font-family: "RobotoCondensedMed";
  cursor: pointer;
  width: auto;
  display: flex;
  flex-direction: row;
  color: var(--coalVeryLight);
}

.planOptionNewDisabled {
  padding: 0.3rem 1rem;
  font-family: "RobotoCondensedMed";
  cursor: default;
  width: auto;
  display: flex;
  flex-direction: row;
  color: var(--grey);
  pointer-events: none;
}

.planOptionNew:hover {
 
  text-decoration: underline;
  color: var(--coalLight)
}

.planOptionNewDisabled:hover {
 
  text-decoration: none;
  color: var(--greyVeryLight)
}
.moduleContainer {
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    border: 0.5px solid #DADADA;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
    border-radius: 1.3rem;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 3rem 1.5rem 3rem;
    font-family: "Roboto";
    font-size: 1.4rem;
    color: var(--coalVeryLight);
}

.header{
    margin: 0;
    padding: 0;
    font-family: "TruenoRg";
    font-size: 2.1rem;
    font-weight: normal;
    color: var(--coalLight);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.line {
    margin: 0.3rem 0rem 0.6rem 0rem;
    width: calc(100% + 1rem);
    height: 1px;
    background: var(--greyVeryLight);
}

a {
    font-family: inherit;
    font-weight: bold;
}

b {
    font-weight: bold;
}


.headerIcon {
    height: 2rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-family: "FontAwesomeSolid";
    margin-left: -0.8rem;
    margin-right: 0.9rem;
    margin-bottom: -0.3rem;
}
.container {
    margin: 2.5rem 0 1rem 0;
    justify-content: space-between;
    align-items: flex-start;
}

.column {
    width: 48%;
    flex-direction: column;
}

.questionRow {
    width: 100%;
    margin-bottom: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "TruenoRg";
}

.questionNumber {
    height: 4rem;
    width: 4rem;
    font-weight: bold;
    font-size: 2.1rem;
    margin-right: 1.5rem;
    color: var(--grey);
}

.questionContent {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.questionName {
    height: 4rem;
    font-family: "TruenoSbd";
    /* font-weight: 600; */
    color: var(--coalight);
}

.answer {
    max-height: 0;
    opacity: 0;
    transition: all 0.1s linear;
    margin-bottom: 1rem;
    color: var(--greyDark);
}

.answerExpanded {
    margin-top: 0.2rem;
    margin-bottom: 1.2rem;
    max-height: 15rem;
    opacity: 1;
}

.iconWrapper {
    position: relative;
    user-select: none;
    cursor: pointer;
    margin-left: 1rem;
}

.iconWrapper:hover {
    opacity: 0.8;
}

.iconHex {
    height: 4rem;
    width: 4rem;
    filter: drop-shadow(0px 0.2rem 0.2rem rgba(0, 0, 0, 0.19));
}

.iconHexSelected {
    filter: drop-shadow(0px 0.2rem 0.2rem rgba(0, 0, 0, 0));
}

.plusMinusWrapper {
    position: absolute;
    margin-left: 0.1rem;
    margin-top: -0.4rem;

    font-size: 2.4rem;
    color: var(--greyVeryLight)
}

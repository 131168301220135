.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}



.confirmationContent {
  flex-direction: column;
  justify-content: flex-start;
}

.checkIcon {
  margin-bottom: -0.8rem;
  max-height: 35vh;
}

.confirmationHeader {
  font-family: "TruenoRg";
  color: var(--coalLight);
  font-size: 2rem;
  margin-bottom: 2rem;
}

.confirmationText {
  display: block;
  width: 50rem;
  text-align: center;
  font-family: "Roboto";
  color: var(--coalVeryLight);
}

.buttonRow {
  margin-top: 2rem;
  width: 70%;
}
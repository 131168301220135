
.logoContainer {
    height: 16%;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -5vh;
    z-index: 3;
}

.logoContainerNextStep {
    animation: shrink 0.3s ease;
}

.logoContainerPrevStep {
    height: 20%;
    animation: grow 0.3s ease;
}

@keyframes shrink {
    0% { height: 20% }
    100% { height: 16% }
  }

@keyframes grow {
    0% { height: 16% }
    100% { height: 20% }
}
  
.bbbLogo {
    margin-top: 1vh;
    height: 100%;
}

.detailsHeader {
    margin-bottom: 3rem;
}

.detailsContent {
    width: 90%;
    height: 100%;
    margin-top: 2rem;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.9s;
}

.detailsContentHidden {
    opacity: 0;
}

.detailsRow {
    width: 100%;
    gap: 2.2rem;
    justify-content: space-between;
    margin-bottom: 1.4rem;
}

.buttonRow {
    margin-top: 2rem;
    width: 100%;
    gap: 2.2rem;

}

.errorPromptContainer {
    height: 2rem;
}
.container{
    font-family: "RobotoCondensedBold";
    font-size: 10px;
    letter-spacing: -0.04rem;
    color: var(--coalLight);
    font-size: 1.15rem;
    padding: 0.3rem 0.6rem;
    background-color: var(--coalLight);
    color: #fff;
    border-radius: 10px;
    box-shadow: 1.63506px 3.27012px 3.59713px rgba(0, 0, 0, 0.25), inset 0px 0px 6.54024px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    position: relative;
}

.container:hover {
    z-index: 9990;
    background-color: var(--coalVeryLight);
}

.text {
    pointer-events: none;
}

.blackContainerContent {
  width: 30%;
  height: 100%;
  position: relative;
}

.bbbLogoWhiteContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem 0 0 2.5rem;
}

.bbbLogoWhite {
  max-width: 3.7rem;
}

.bbbLogoText {
  font-family: "TruenoBd";
  font-weight: 700;
  color: white;
  font-size: 1.4rem;
  margin-left: 0.4rem;
  margin-bottom: 0.1rem;
}

.welcomeMessageContainer {
  height: fit-content;
  align-items: flex-start;
  width: 75%;
}

.welcomeHeader {
  display: block;
  font-family: "TruenoSBd";
  font-weight: 600;
  font-size: 3.8rem;
  color: var(--leaf);
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25), 3px 4px 4px rgba(0, 0, 0, 0.47);
}

.welcomeText {
  color: var(--grey);
  font-size: 1.7rem;
  display: block;
}

.headerGreen {
  color: var(--grass);
}

.textLink {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.textLink:hover {
  opacity: 0.7;
}

.welcomeMessageTextContainer {
  flex-direction: column;
  align-items: flex-start;
}


.mainWhiteContainer {
  width: 70%; /*make pixel based?*/
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  gap: 0.5rem;
}

.loginOptionsContainer {
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
}

.forgotPassword {
  color: var(--coalLight);
  font-family: "RobotoCondensedBold";
  font-size: 1.3rem;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.forgotPassword:hover {
  opacity: 0.7;
}


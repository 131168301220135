.container {
    
}



.logoContainer {
    height: 20%;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -5vh;
    z-index: 3;
}
  
.bbbLogo {
    margin-top: 1vh;
    height: 100%;
}

.greenCirclesWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
    pointer-events: none;
    overflow: hidden;
}

.greenCircle1 {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 3.5vw;
    border-radius: 0px 20px 0px 0px;
    transition: all 0.4s;
}

.greenCircle1Hidden {
    max-width: 0vw;
}

.greenCircle2 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    max-width: 3.5vw;
    border-radius: 0px 20px 0px 0px;
    transform: rotateZ(180deg);
    transition: all 0.4s ease;
}

.greenCircle2Hidden {
    max-width: 0vw;
}

.loginHeader {  
    color: var(--coal);
}
  

.referralContent {
    flex-direction: column;
    max-width: 400px;
    opacity: 1;
    transition: opacity 0.4s;
}

.referralContentHidden {
    opacity: 0;
}

.errorPromptContainer {
    height: 2rem;
}
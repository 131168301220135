

/* .overlayBg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
} */

.overlayBg {
    position: fixed;
    top: 0;
    left: 10px;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);

}

.overlay {
    background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
    border: 0.5px solid #DADADA;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12);
    border-radius: 1.3rem;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem 3rem 1.5rem 3rem;
    font-family: "Roboto";
    font-size: 1.4rem;
    color: var(--coalVeryLight);
}
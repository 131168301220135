.tooltip {
    font-family: "FontAwesomeLight";
    color: var(--grey);
    position: relative;
    margin-left: 0.2rem;
    cursor: help;
}

.small {
    font-size: 1.2rem;
    bottom: 0.6rem;
}

.medium {
    font-size: 1.5rem;
    bottom: 0.7rem;
}

.large {
    font-size: 1.8rem;
    bottom: 1rem;
}

.hoverArea {
    position: absolute;
    bottom: -1rem;
    right: -0.6rem;
    width: 2.8rem;
    height: 2.8rem;
}

.bubble {
    position: absolute;
    background: var(--coal);
    padding:  1rem 1.5rem 1.2rem 1.5rem;
    border-radius: 0.7rem;
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 1.4rem;
    color: white;
    letter-spacing: -0.015em;
    transition: opacity 0.2s linear;
    user-select: none;
    pointer-events: none;
    box-shadow: 0px 3.38126px 8.45314px rgba(0, 0, 0, 0.3);
}

.bubbleHidden {
    opacity: 0;
}

.bottomLeft {
    top: 2rem;
    right: -1.2rem;
}

.arrowBottomLeft {
    position: absolute;
    top: -1rem;
    right:1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1.3rem solid var(--coal);
}

.bottomRight {
    top: 2rem;
    left: -1.2rem;
}

.arrowBottomRight {
    position: absolute;
    top: -1rem;
    left:1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1.3rem solid var(--coal);
}


.topLeft {
    bottom: 2rem;
    right: -1.2rem;
}

.arrowTopLeft {
    position: absolute;
    bottom: -1rem;
    right:1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1.3rem solid var(--coal);
}

.topRight {
    bottom: 2rem;
    left: -1.2rem;
}

.arrowTopRight {
    position: absolute;
    bottom: -1rem;
    left:1rem;
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1.3rem solid var(--coal);
}
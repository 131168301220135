.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.contentGrid {
  width: 100%;
  margin-left: 0px;
  position: relative;
}

.headerRow {
  width: 100%;
  margin-top: -0.7rem;
  height: 3.4rem;   
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.6rem;
  font-family: "TruenoRg";
  font-size: 1.6rem;
  border-bottom: 1px solid var(--greyLight);
}

.headerFilter {
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

.headerFilter:hover {
  opacity: 0.6;
}

.headerFilterSelected {
  height: calc(100% - 2px);
  color: var(--coalLight);
  border-bottom: 2px solid var(--coalLight);
}

.orderTable {
  width: 100%;
  margin-top: 1rem;
  border-spacing: 0 1.2rem;
}

.orderHeaderRow{
  text-align: left;
}

th:first-child{
  padding-left: 1rem;
}

td:first-child{
  padding-left: 1.4rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -webkit-border-radius: 1rem 0 0 1rem;
}
td:last-child{
  -moz-border-radius: 0 1rem 1rem 0;
  -webkit-border-radius: 0 1rem 1rem 0;
}



.tableRow{
  
  width: 100%;
  height: 5rem;
  height: 100%;
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: left;
  border-radius: 6px;
  
  background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 0.01%, #F8F8F8 39.21%, #FFFFFF 83.5%, #FFFFFF 102.14%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.073), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
} 



.dataBold {
  font-weight: 700;
  padding: 2rem 0;
}

.exp {
  font-size: 0.8rem;
  position: relative;
  bottom: 0.5rem;
}


.statusDelivered {
  color: var(--leaf);
  font-weight: 700;
}

.statusUpcoming {
  color: var(--coal);
  font-weight: 700;
}

.statusCancelled {
  color: var(--error);
  font-weight: 700;
}

.statusIcon {
  font-family: "FontAwesomeSolid";
  margin-right: 0.4rem;
}


.newZoneButtonContainer {
  width: 140px;
  position: absolute;
  top: -80px;
  right: 70px;
}

.geometrySettingsContainer {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.toggleSwitchContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "RobotoCondensedMed";
}

.resetButtonContainer {
  height: 100%;
  padding: 10px 0 !important; 
  width: 300px;
  gap: 10px;
}

.contentWrapper {
  /* background-color: pink; */
  padding-top: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  width: 75%;
}

.detailsRow {
  width: 100%;
  gap: 3rem;
  justify-content: space-between;
  
}

.halfRow {
  padding-right: calc(50% + 1.5rem);
}

.buttonRow {
  margin-top: 2rem;
  width: 100%;
  gap: 3rem;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}

.checkBoxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  /* background-color: pink; */
  padding: 1.5rem 0 0rem 0.5rem;
}




.zoneTag {
  font-family: "RobotoCondensedMed";
  letter-spacing: -0.2px;
  font-size: 13px;
  padding: 2px 9px;
  border-radius: 7px;
  color: var(--leaf);
  background-color: white;
  box-shadow: 0.87509px 0.16679px 0.3336px rgba(0, 0, 0, 0.13);

  cursor: pointer;
  min-width: 4rem;
  user-select: none;
  border: 1.5px solid var(--leaf);
}

.selectedZone {
  background-color: var(--leaf);
  border: 1.5px solid transparent;
  box-shadow: 1.87509px 2.16679px 1.3336px rgba(0, 0, 0, 0.13), inset 0px 0px 36.6672px rgba(0, 0, 0, 0.08);

  color: white;

}

.detailsRowHeader {
  font-family: "RobotoCondensedMed";
  font-size: 1.5rem;
  color: var(--coalLight);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.targetedZonesWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
}
.zonesRow {
  margin: 1rem 0;
  margin-bottom: 1rem;;
  gap: 0.7rem;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap; /* This is the key */

}

.orderTable{
        /* Set a reasonable upper bound */
  transition: all 0.5s ease;
  
  /* background-color: pink; */
  /* align-items: top; */
  /* border-collapse: collapse;
  position: absolute;         */
}

.tableContainer {
  /* background-color: pink; */
  /* height: 100%;*/
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: stretch; */
  /* border: 1px solid #ddd; */
  /* background-color: yellow; */
  border-radius: 8px;
  margin-top: 0rem;
  min-height: 200px;
  max-height: 2000px;      /* Set a reasonable upper bound */
}

.typeIcon {
  max-height: 2.3rem;
  margin-top: -0.6rem;
  margin-right: 0.8rem;
}

.zoneRowName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  color: var(--coal);
}

.dataInputText {
  max-height: 2.3rem;
  padding: 0.8rem;
  max-width: 3rem;
  color: var(--greyDark);
  font-family: "RobotoCondensedMed";
  font-size: 1.7rem;
  border: 1px solid var(--greyLight);
  border-radius: 6px;
  background-color: white;  
  text-align: center;
  align-items: center;
}

.dataInputText:focus {
  outline: 2px solid #B0E000;
  color: var(--leaf);
}


.zoneRowRemoveButton {
  width: 0;
}
.zoneRowRemoveIcon {
  margin-right: 5rem;

  font-family: "FontAwesomeSolid";
  font-size: 1.5rem;
  color: var(--error);
  opacity: 0.8;
  cursor: pointer;
}

.breakdownRow {
  width: 95%;
  gap: 3rem;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 3.5rem;
}

.separatorLine {
  width: 1px;
  height: 100%;
  background: var(--greyLight);
  margin: 1rem 0;
}

.breakdownItem {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "RobotoCondensedMed";
  font-size: 1.5rem;
  color: var(--coalLight);
}

.breakdownValue {
  font-family: "TruenoSBd";
  font-size: 2.5rem;
  color: var(--leaf);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.unit {
  color: var(--grass);
  font-size: 1.7rem;
  line-height: 2.5rem;
  margin-left: 0.5rem;
}
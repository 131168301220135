.CheckboxRow {
    height: 1.8rem;
    font-family: "RobotoCondensedBold";
    font-size: 1.3rem;
    color: var(--coalLight);
    letter-spacing: -0.02rem;
    user-select: none;
    margin-right: 10px;
    /* background-color: pink; */
    /* padding: 4px; */
}

.CheckboxRowInverted {
    flex-direction: row-reverse;
}

.Checkbox {
    border: 0.18rem solid var(--coalVeryLight);
    border-radius: 0.3rem;
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    margin-right: 0.75rem;
    transition: all 0.2s ease;
    cursor: pointer;
    margin-right: 0.75rem;
  }

.CheckboxInverted {
    margin-left: 0.75rem;

}
  
.Checkbox:hover {
    background: #F4EEFF;
    border-radius: 0.45rem;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.65rem;
    margin-left: -0.1rem;
}

.CheckboxInverted:hover {
    margin-left: 0.65rem;
    margin-row: -0.1rem;
}
  
.Check {
    width: 0rem;
    height: 0rem;
    background: var(--coalLight); 
    border-radius: 0.225rem;
    transition: all 0.3s linear;
}
  
.Checked {
    width: 1rem;
    height: 1rem;
  }
  
.thinnerText {
    font-weight: 400;
    font-family: "RobotoCondensedMed";
    color: var(--coalVeryLight);
}
.container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; 
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    z-index: 98;
    padding: 20px;
    overflow: visible;
    padding-top: 30px;
}

.shoppingBagSelector {
    max-height: 20px;
}


.scanResult {
    background-color: white;
    border: 1px solid black;
}



.logoContainer {
    position: absolute;
    z-index: 9999;
    top: -110px;
    width: 70px;
    height: 180px;
    border-radius: 100%;
    background-color: #FFFFFF;
  }
  
  /* bbb_logo_standard_no_text 1 */
  
  
  
  .logo {
    max-width: 60px;
    margin-top: 4px;
    left: 15px;
    filter: drop-shadow(0px 6.07027px 6.07027px rgba(0, 0, 0, 0.25));
  }

  
.reada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 280px;
    border-radius: 15px;
    box-shadow: 1.63506px 3.27012px 9.59713px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    position: relative;
}

.qrIcon {
    position: absolute;
    top: auto;
    margin-top: -50px;
    width: 170px;
    height: 170px;
    z-index: 999;
    opacity: 0.4;
    pointer-events: none;
}

.manualEntryContainer {
    position: absolute;
    bottom: auto;
    margin-top: 200px;
    width: 180px;
    height: 20px;
    z-index: 9999999;
}

.manualEntryIconWhite {
    position: absolute;
    right: 10px;
    top: auto;
    margin-top: 5px;
    max-height: 14px;
    transform: rotate(-90deg);
} 

.manualEntryTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.manualCharSpan {
    font-size: 18px;
    height: 30px;
    width: 100px;
}

.scannedCodes {
    /* background-color: green; */
    /* border: 1px solid black; */
    margin-bottom: 12px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    /* padding: 10px; */
    justify-content: flex-start;
    justify-content: center;
    gap: 5px;
}

.scannedCodesCollapsed {
    height: -10px;
}

.collapseIcon {
    max-height: 15px;
    margin-left: 10px;
    margin-bottom: 3px;
}

.collapsedIcon {
    transform: rotate(180deg);
}

.charcodeTag {
    font-family: "RobotoCondensedBold";
    letter-spacing: -0.06rem;
    color: var(--coalLight);
    font-size: 1.1rem;
    padding: 0.3rem 0.6rem;
    background-color: var(--coalLight);
    color: #fff;
    border-radius: 6px;
    box-shadow: 1.63506px 3.27012px 3.59713px rgba(0, 0, 0, 0.25), inset 0px 0px 6.54024px rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.charcodeTagCollapsed {
  height: 0px;
  padding: 0px;
  font-size: 0px;
  opacity: 0;
}

.backspaceIcon {
    font-family: "FontAwesomeSolid";
    letter-spacing: 0;
    font-size: 13px;
    margin-left: 3px;
}

.bagIcon {
    max-height: 1.8rem;
}

.bagsNumber {
    font-size: 18px;
    margin-top: 10px;
    font-family: "RobotoCondensedMed";
    height: 60px;
    width: 100%;
    color: var(--coalLight);
}

.closeIcon {
    font-family: "FontAwesomeSolid";
    color: white;
    width: 30px;
    height: 30px;
    background-color: var(--coal);
    border-radius: 6px;
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    box-shadow: 1.63506px 3.27012px 9.59713px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.buttonContainer {
    width: 100%;
}

.closeIcon:hover {
    opacity: 0.7;
}

.deleteIcon {
    font-family: "FontAwesome6";
    color: white;
    width: 30px;
    height: 30px;
    background-color: var(--error);
    border-radius: 6px;
    font-size: 20px;
    position: relative;
    top: 20px;
    right: 70px;
    box-shadow: 1.63506px 3.27012px 9.59713px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.header{
    font-family: "RobotoCondensedBold";
    /* font-weight: bold; */
    font-size: 23px;
    margin-bottom: 10px;
    color: var(--coalLight);
    position: relative;
    z-index: 120;
}


.fontAwesome {
    font-family: "FontAwesomeSolid";
    margin-right: 10px;
}
.logo {
    max-width: 100px;
}

.qrContainer {
    position: relative;;
}

.invalidCharcodeError {
    position: absolute;
    font-family: "RobotoCondensedMed";
    font-size: 14px;
    bottom: -20px;
    color: var(--error);
}
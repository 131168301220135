.qrContainer {
    background-color: #fff;
    position: relative;
    width: 145px;
    height: 170px;
    border: 6px solid black;
    border-radius: 13px;
}

.qrCode {
    margin-top: -27px;
    width: 90%;
    transform: scale(0.2);
}

.qrOuterBorder {
    border: 1px solid var(--grey);
    border-radius: 8px;
    padding: 12px 10px 20px 10px;
    position: relative;
    transform: scale(4.5);

}


  

.qrOuterBorderHidden {
    border: none;
    padding: 0px;
}

.qrBottomLabel {
    position: absolute;
    bottom: -15px;
    width: 80%;
    background: white;
    display: flex;
    flex-direction: column;
}

.scanToConfirm {
    font-family: "TruenoBd";
    font-weight: 900;
    font-size: 12px;
}

.instructions {
    color: #ACACAC;
    font-family: "RobotoCondensedBold";
}

.charcode {
    position: absolute;
    height: 25px;
    font-family: "RobotoCondensedBold";
    letter-spacing: -0.3px;
    width: 100%;
    font-size: 18px;
    background-color: black;
    bottom: 0px;
    display: flex;
    align-items: center;
    padding-top: 6px;
    color: #fff;
}

.cha {
    font-family: "RobotoCondensed";
}


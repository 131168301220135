.bgContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(156deg, rgba(3,3,3,1) 1%, rgba(32,32,32,1) 40%, rgba(70,70,70,1) 100%);
}

.mainWhiteContainer {
  
}

.mainBlackContainer {
  height: 100vh;
  width: 100vw;
  margin: 0;
}


.contactFormText {
  display: block;
  width: 100%;
}

.inputEmail {
  width: 40%;
}

.inputSubject {
  width: 60%;
}

.contactFormRow {
  margin: 2rem 0rem 1rem 0rem;
  width: 100%;
  gap: 2rem;
  justify-content: space-between;
}

.contactFormButtonRow {
  width: 100%;
  justify-content: flex-end;
  margin: 2rem 0rem 0rem 0rem;
}

.contactFormButtonWrapper {
  width: 35rem;
}

.errorPromptContainer {
  width: 60%;
}

.messageSent {
  height: 6rem;
  font-size: 1.5rem;
}